import React, { useState } from "react";
import { Form , Button ,Row ,Col , DatePicker, Table, Divider } from 'antd';
import { getRangeOptions } from './reports.module';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch, useSelector } from "react-redux";
import { getRevenueData } from "../../actions/revenue.actions";
import { getAPIDateFormat, getDayMonthInReadable, incrementDays } from "../../utils/date.utils";
import { revenueSelector } from "../../selector/revenue.selector";
import { useEffect } from "react";
import { isValid } from "../../utils/utilities";
import moment from "moment";
import { getPercentage } from "../../utils/math.utils";

let allowedMaxDaysRange=31;

const getBreakEvenBlock=(exactRevenue,breakevenRevenue,maxRevenue)=>{
    if(exactRevenue>breakevenRevenue){
        var percentageValue =  exactRevenue / maxRevenue;
        return(
            <div style={{
                background:`rgba(98, 237, 18, ${percentageValue})`,
                paddingTop:'10px',
                paddingBottom:'10px',
                paddingLeft:'2px',
                color:'black',
                fontWeight:'bold'
            }}>
                {exactRevenue}
            </div>
        )
    }else{
        let percentageValue = 1;
        if(exactRevenue>0){
            percentageValue =1-exactRevenue / breakevenRevenue;
        }
        return(
            <div style={{
                background:`rgba(242, 16, 16, ${percentageValue})`,
                paddingTop:'10px',
                paddingBottom:'10px',
                paddingBottom:'10px',
                paddingLeft:'2px',
                color:'black',
                fontWeight:'bold'
            }}>
                {exactRevenue}
            </div>
        )
    }
}

const getConfirmedSeatCount=(trips,date)=>{
    if(isValid(trips[date])){
        let availableSeats = trips[date].availableSeats;
        let confirmedSeats = trips[date].confirmedSeats;
        let spanOffeset = getPercentage(confirmedSeats,availableSeats+confirmedSeats);

        if(spanOffeset<40 && spanOffeset !=0){
            spanOffeset=40;
        }
        return(
            <Row>
                <Col style={{
                    width:`${spanOffeset}%`,
                    background:'#1EB100',
                    color:'white',
                    fontWeight:'bold',
                    textAlign:'center'
                }}>
                    {confirmedSeats}
                </Col>
                <Col
                style={{
                    width:`${100-spanOffeset}%`,
                    background:'#E6240F',
                    color:'white',
                    fontWeight:'bold',
                    textAlign:'center'
                }}
                >
                    {availableSeats}
                </Col>
            </Row>
        )
    }else{
        return null
    }
}

const getBreakEvenRevenue=(trips,date)=>{
    if(isValid(trips[date])){
        return trips[date].breakevenRevenue
    }else{
        return null
    }
}

const getCurrentRevenue=(trips,date)=>{
    if(isValid(trips[date])){
        return trips[date].currentRevenue
    }else{
        return null
    }
}

const getMaxRevenue=(trips,date)=>{
    if(isValid(trips[date])){
        return trips[date].maxRevenue
    }else{
        return null
    }
}

const getFormatedRevenueDetails=(revenueDetails,currentDate)=>{
    let formatedInfo = [];
    let text3date = getAPIDateFormat(currentDate);
    let text4date = getAPIDateFormat(incrementDays(currentDate,1));
    let text5date = getAPIDateFormat(incrementDays(currentDate,2));
    let text6date = getAPIDateFormat(incrementDays(currentDate,3));

    for (let index = 0; index < revenueDetails.length; index++) {
        const schedule = revenueDetails[index];
        const trips = revenueDetails[index].trips;

        
        formatedInfo.push({
            text1:schedule.description,
            rowSpan:4,
            text2:"Occupancy",
            text3:getConfirmedSeatCount(trips,text3date),
            text4:getConfirmedSeatCount(trips,text4date),
            text5:getConfirmedSeatCount(trips,text5date),
            text6:getConfirmedSeatCount(trips,text6date)
        });
        formatedInfo.push({
            text1:'',
            rowSpan:0,
            text2:"Current Revenue",
            text3:getCurrentRevenue(trips,text3date),
            text4:getCurrentRevenue(trips,text4date),
            text5:getCurrentRevenue(trips,text5date),
            text6:getCurrentRevenue(trips,text6date),
            breakEven:{
                text3:getBreakEvenRevenue(trips,text3date),
                text4:getBreakEvenRevenue(trips,text4date),
                text5:getBreakEvenRevenue(trips,text5date),
                text6:getBreakEvenRevenue(trips,text6date)
            },
            maxRevenue:{
                text3:getMaxRevenue(trips,text3date),
                text4:getMaxRevenue(trips,text4date),
                text5:getMaxRevenue(trips,text5date),
                text6:getMaxRevenue(trips,text6date)
            },

        });
        formatedInfo.push({
            text1:'',
            rowSpan:0,
            text2:"Breakeven Revenue",
            text3:getBreakEvenRevenue(trips,text3date),
            text4:getBreakEvenRevenue(trips,text4date),
            text5:getBreakEvenRevenue(trips,text5date),
            text6:getBreakEvenRevenue(trips,text6date)
        });
        formatedInfo.push({
            text1:'',
            rowSpan:0,
            text2:"Max Revenue @ Current Price",
            text3:getMaxRevenue(trips,text3date),
            text4:getMaxRevenue(trips,text4date),
            text5:getMaxRevenue(trips,text5date),
            text6:getMaxRevenue(trips,text6date)
        });
    }
    return formatedInfo;
}


export const RevenueReport = () => {

    const [form] = Form.useForm();
    const [currentDate,setCurrentDate] = useState(moment());
    const [formatedRevenueDetails,setFormatedRevenueDetails] = useState([]);

    const dispatch = useDispatch();

    const revenueDetails = useSelector(revenueSelector);

    const [showReport,setShowReport]=useState(false);

    const onSuccess=(data)=>{
        setLoading(false);
        setShowReport(true)
    }

    const onFailure=()=>{
        setLoading(false);
        setShowReport(false);
    }

    const onFinish=(values)=>{
        setCurrentDate(values.startDate);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const getDateTitle=(i)=>{
        return getDayMonthInReadable(incrementDays(currentDate,i));
    }

    useEffect(()=>{
        const fromDate = getAPIDateFormat(currentDate);
        const toDate = getAPIDateFormat(incrementDays(currentDate,4));
        setLoading(true);
        dispatch(getRevenueData(fromDate,toDate,onSuccess,onFailure));
    },[currentDate]);

    useEffect(()=>{
        setFormatedRevenueDetails(getFormatedRevenueDetails(revenueDetails,currentDate));
    },[revenueDetails]);

    let revenueColumns = [
        {
            title:"Trip Description",
            dataIndex:"text1",
            render: (text, row, index) => {
                if (row.rowSpan != 0) {
                   return {
                       children: <div style={{marginRight:'20px',paddingLeft:'10px'}}>{text}</div>,
                       props: {
                         rowSpan: row.rowSpan
                       }
                   };
                 }
                
               return {
                children: <div style={{marginRight:'20px',paddingLeft:'10px'}}>{text}</div>,
                props: {
                       rowSpan: row.rowSpan
                     }
               };
           }
        },
        {
            title:"",
            dataIndex:"text2",
            render:(text)=>{
                return(
                    <div style={{marginRight:'20px',paddingLeft:'10px'}}>{text}</div>
                )
            }
        },
        {
            title:getDateTitle(0),
            dataIndex:"text3",
            render:(text,row)=>{
                if(row.text2 == "Current Revenue"){
                    return getBreakEvenBlock(row.text3,row.breakEven.text3,row.maxRevenue.text3)
                }else if(row.text2 == "Occupancy"){
                    return text
                }else{
                    return (<div style={{paddingLeft:'10px'}}>{text}</div>);
                }
            }
        },
        {
            title:getDateTitle(1),
            dataIndex:"text4",
            render:(text,row)=>{
                if(row.text2 == "Current Revenue"){
                    return getBreakEvenBlock(row.text4,row.breakEven.text4,row.maxRevenue.text4)
                }else if(row.text2 == "Occupancy"){
                    return text
                }else{
                    return (<div style={{paddingLeft:'10px'}}>{text}</div>);
                }
            }
        },
        {
            title:getDateTitle(2),
            dataIndex:"text5",
            render:(text,row)=>{
                if(row.text2 == "Current Revenue"){
                    return getBreakEvenBlock(row.text5,row.breakEven.text5,row.maxRevenue.text5)
                }else if(row.text2 == "Occupancy"){
                    return text
                }else
                {
                    return (<div style={{paddingLeft:'10px'}}>{text}</div>);
                }
            }
        },
        {
            title:getDateTitle(3),
            dataIndex:"text6",
            render:(text,row)=>{
                if(row.text2 == "Current Revenue"){
                    return getBreakEvenBlock(row.text6,row.breakEven.text6,row.maxRevenue.text6)
                }else if(row.text2 == "Occupancy"){
                    return text
                }else{
                    return (<div style={{paddingLeft:'10px'}}>{text}</div>);
                }
            }
        }
    ];

    return (
            <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    initialValues={{startDate:currentDate}}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="startDate" label="Select Date" 
                               rules={[
                                { required: true , message: 'Please input your date range!'}
                            ]}>
                                    <DatePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport?
                    <Row>
                        <Col>
                            <Table 
                                className="revenue-details bordered-table"
                                columns={revenueColumns}
                                dataSource={formatedRevenueDetails}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                    :null
                }
            </div>
    )
}