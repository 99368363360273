import React, {  } from "react";
import { Select,Form } from 'antd';
import { isValid,isValidArray, isValidNumber } from "../../../utils/utilities";
import   './schedule-select.style.scss';

const { Option } = Select;

const renderSchedules=(schedules,key,isTripSheetPermissionCheck)=>{
    let scheduleArray=[];
    if(isValidArray(schedules)&&schedules.length>0){
        schedules.forEach(element => {
            if(isValidArray(element)){
                let isAllowed = (isTripSheetPermissionCheck && (!isValidNumber(element.trip_sheet_permission)||element.trip_sheet_permission === 1)) || !isTripSheetPermissionCheck ? true : false; 
                if(isAllowed){
                    scheduleArray.push(<Option key={element.id} value={element[key]} schedule={element}>{element.description}</Option>)
                }
            }else{
                scheduleArray.push(<Option key={element} value={element} schedule={element}>{element}</Option>)

            }    
        });
    }
    return scheduleArray;
}

export const ScheduleSelect = ({schedules,optionKey,showLabel=false,isManditory=true,isFixedWidth=false,isTripSheetPermissionCheck=false}) => {
    return (
        <Form.Item
        name="returnId"
        label={showLabel?"Select Schedule":""}
        rules={[{ required: isManditory, message: 'Please input your schedule!' }]}
        >
             <Select
                size="large"
                showSearch
                placeholder="Select a schedule"
                optionFilterProp="childrens"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                bordered={true}
                className={isFixedWidth?"defalut-select-style":"report-schedule-select-style"}
            >
            {renderSchedules(schedules,optionKey,isTripSheetPermissionCheck)}
            </Select>
        </Form.Item>
    )
}