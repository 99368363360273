import React , { useState } from "react";
import './../../styles/report.style.scss';
import { SearchScheduleComponent } from './../../components/search-components/search-schedule-component/search-schedule.component';
import { Form , Button , Row , Col , Typography , Table, Modal } from 'antd';
import { isValid, isValidArray, isValidNumber } from "./../../utils/utilities";
import { ScheduleSelect} from './../../components/search-components/schedule-select/schedule-select.component';
import {
    getLocalDate,
    convertDateStringToDate,
    getLocalTime,
    convertTimeStringToDate,
    getLocalDateAndTime,
    getTimeStampInReadableFormat
} from './../../utils/date.utils';
import { fetchTripSheet } from './../../actions/reports.action';
import { useDispatch } from "react-redux";
import { loadingAction } from './../../actions/loading-actions';
import { roundNumber, subtractFloatingPoints as SFP } from './../../utils/math.utils';
import { Printer } from './../../components/custom-components/custom-component';
import { showConfirm } from './../../components/custom-components/custom-component';
import { tripSheetReportConfig , AVAIABLE_SEATS , ADMIN_BLOCKED_SEATS} from './reports-config';
import { sortJsonObject } from './../../utils/utilities';
import Icon from '@ant-design/icons';
import PrintIcon from './../../images/print.png';
import { CancelledTripSheet } from "../../components/cancelled-trip-sheet-report/cancelled-trip-sheet-report.component";
import { TripSheetPdf } from "../pdfs/trip-sheet.pdf";
import MobileCall from "../pdfs/common/Link";
import { TicketExcelReport } from "../excels/ticket-excel.report";
import { MiniTripSheetPdf } from "../pdfs/mini-trip-sheet.pdf";
import { RTOTripSheet } from "../pdfs/rto-trip-sheet";


const notBoardedColumns = [
    {
      title: 'Seat No',
      dataIndex: 'seat_number',
      key: 'seat_number',
      className:"control-margin",
      pdfRender: ({text}) => text
    },
    {
      title: 'Ticket No',
      dataIndex: 'pnr',
      key: 'pnr',
      className:"control-margin",
      pdfRender: ({text}) => text
    },
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
      className:"control-margin",
      pdfRender: ({text}) => text
    },
    {
      title: 'Boarding',
      dataIndex: 'location',
      key: 'location',
      className:"control-margin",
      pdfRender: ({text}) => text
    },
    {
      title: 'Destination',
      dataIndex: 'drop_city',
      key: 'drop_city',
      className:"control-margin",
      pdfRender: ({text}) => text
    },
    {
      title: 'Drop Off',
      dataIndex: 'drop_location',
      key: 'drop_location',
      className:"control-margin",
      pdfRender: ({text}) => text
    },
    {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        className:"control-margin",
        pdfRender: ({text}) => text
    }
  ];

export const getTitleForTripSheet=(defaultTitle,key)=>{
    if(isValidArray(global.tripSheetConfig)){
        let {
            tripSheetColConfig
        } = global.tripSheetConfig;
        if(isValid(tripSheetColConfig[`${key}_title`])){
            return tripSheetColConfig[`${key}_title`];
        }else{
            return defaultTitle;
        }
    }else{
        return defaultTitle;
    }
}
export const getOrderForTripSheet=(key)=>{
    if(isValidArray(global.tripSheetConfig)){
        let {
            tripSheetColConfig
        } = global.tripSheetConfig;
        if(isValid(tripSheetColConfig[key])){
            return tripSheetColConfig[key];
        }else{
            return 0;
        }
    }else{
        return 0;
    }
}


export const showColumn =(key)=>{
    if(isValidArray(global.tripSheetConfig)){
        let {
            tripSheetColConfig
        } = global.tripSheetConfig;

        if(isValid(tripSheetColConfig[key])){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}

export const showTableAsSingleTable =()=>{
    if(isValidArray(global.tripSheetConfig)){
        if(global.tripSheetConfig['show_trip_sheet_in_single_table'] == 1){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}

export const showLogo =()=>{
    if(isValidArray(global.tripSheetConfig)){
        if(global.tripSheetConfig['show_logo_in_trip_sheet'] == 1){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}


const RenderLogo =({logoUrl})=>{
    if(showLogo()&&isValid(logoUrl)){
        return (
            <img src={logoUrl} alt={global.operatorName}
                className="logo-in-ticket"
            />
        )
    }else{
        return null
    }
}

const renderCity =()=>{
    if(isValidArray(tripSheetReportConfig[global.operatorCode])){
        if(isValid(tripSheetReportConfig[global.operatorCode].operatorCity)){
            return tripSheetReportConfig[global.operatorCode].operatorCity;
        }else{
            return null;
        }
    }else{
        return null;
    }
}


const getAgentsInfo = (bookingInfo,showSpecificAgent) =>{

    let filteredData = [];
    let totalSeats = 0;
    let totalFare = 0, totalAmount = 0;
    let totalCommission = 0;
    let totalCancelledSeats = 0;
    let totalCancellationpenality = 0;
    let totalGst = 0;
    let totaltdsAgentComm = 0;

    if(isValidArray(bookingInfo)){
            bookingInfo.forEach((element)=>{
                let { 
                    seatsCount,
                    fare,
                    agentCommission,
                    tdsAgentCommission , 
                    cancelledTicketsCount , 
                    canellationPenality , 
                    gst,
                    privilege,
                    amount
                } = element;
                if(isValidNumber(seatsCount)){
                    totalSeats += seatsCount;
                }
                if(isValidNumber(fare)){
                    totalFare = totalFare + fare;
                }
                if(isValidNumber(agentCommission)){
                    totalCommission = totalCommission + agentCommission;
                }
                if(isValid(cancelledTicketsCount)){
                    totalCancelledSeats = totalCancelledSeats + cancelledTicketsCount;
                }
                if(isValid(canellationPenality)){
                    totalCancellationpenality = totalCancellationpenality + canellationPenality;
                }
                if(isValid(gst)){
                    totalGst = totalGst + gst;
                }
                if(isValid(tdsAgentCommission)){
                    totaltdsAgentComm = totaltdsAgentComm + tdsAgentCommission;
                }
                if(isValidNumber(amount)) {
                    let netAmount = fare+canellationPenality-(agentCommission-tdsAgentCommission);
                    if (privilege != 'apiAgentsInfo') {
                        netAmount+=gst;
                    }
                    totalAmount+= netAmount;
                }
                if(showSpecificAgent){
                    if(global.name == element.agentName){
                        filteredData.push(element);
                    }
                }else{
                    filteredData.push(element);
                }
            });
            if(!showSpecificAgent){
                filteredData.push({
                    "agentName":"Total",
                    "seatsCount":totalSeats,
                    "seatNames":null,
                    "fare":roundNumber(totalFare),
                    "agentCommission":roundNumber(totalCommission),
                    "tdsAgentCommission":roundNumber(totaltdsAgentComm),
                    "cancelledTicketsCount":totalCancelledSeats,
                    "canellationPenality":roundNumber(totalCancellationpenality),
                    "gst":roundNumber(totalGst),
                    "amount": roundNumber(totalAmount)
                });
            }
            return filteredData;
    }else{
        return [];
    }
}



const dropPointsColumns=[
    {
        title:"Seat Numbers",
        key:'seatNumbers',
        dataIndex:'seatNumbers',
        className:"control-margin",
        pdfRender: ({text}) => text,
        pdfWidth : '60%'
    },
    {
        title:"Drop point",
        key:'dropLocation',
        dataIndex:'dropLocation',
        className:"control-margin",
        render : (text, record, index) => (
            <div>
                {text}({record.count})
            </div>
        ),
        pdfRender: ({text,record}) => ` ${text}(${record.count})`,
        pdfWidth :'40%'
    }
]

const getTripSheetReportByBoardingPoint=(tripSheet)=>{
    let tripSheetByBoardingPoints={};
    let dropPointSeatNumber=[];
    let dropLocationIndex={};
    let sharedSeatNumbers = {};

    if(isValidArray(tripSheet)&&tripSheet.length>0){
        for (let index = 0; index < tripSheet.length; index++) {

            const element = tripSheet[index];

            if (isValid(sharedSeatNumbers[element.seat_number])) {
                sharedSeatNumbers[element.seat_number]+=1;
            }
            else {
                sharedSeatNumbers[element.seat_number] = 1;
            }

            if(isValidNumber(element.commission)){
                element.amt=SFP(element.ticket_cost,element.commission);
            }else{
                element.amt=element.ticket_cost
            }
            if(isValid(element.location)){
                if(!isValidArray(tripSheetByBoardingPoints[element.location])){
                    let boardingTime=getTimeStampInReadableFormat(element.pickup_timing);
                    tripSheetByBoardingPoints[element.location]={
                        boradingTickets:[],
                        boardingTime:boardingTime,
                        landmark:element.landmark
                    };
                }
                tripSheetByBoardingPoints[element.location].boradingTickets.push(element);
            }else{
                if(!isValidArray(tripSheetByBoardingPoints["Boarding missed"])){
                    tripSheetByBoardingPoints["Boarding missed"]={
                        boradingTickets:[],
                        boardingTime:null,
                        landmark:null
                    };
                }
                tripSheetByBoardingPoints["Boarding missed"].boradingTickets.push(element);
            }
            if(isValid(element.drop_location)&&isValidNumber(dropLocationIndex[element.drop_location])){
                let currentInfo=dropPointSeatNumber[dropLocationIndex[element.drop_location]];
                dropPointSeatNumber[dropLocationIndex[element.drop_location]]['seatNumbers']=currentInfo.seatNumbers+","+element.seat_number;
                dropPointSeatNumber[dropLocationIndex[element.drop_location]]['count'] =   dropPointSeatNumber[dropLocationIndex[element.drop_location]]['count'] + 1;
            }else{
                dropLocationIndex[element.drop_location]=dropPointSeatNumber.length;
                let destinationDay = element.route_destination_day + element.drop_destination_day;
                dropPointSeatNumber.push({
                    dropLocation:element.drop_location,
                    seatNumbers:element.seat_number,
                    count:1,
                    destination_day : destinationDay,
                    drop_timing:element.drop_timing
                });
            }
        }
    }
    //sort by  destination day

    let sortedResultsByDestionationDay = sortJsonObject(dropPointSeatNumber,'destination_day',true);
    let sortedResultsByTiming = sortJsonObject(sortedResultsByDestionationDay,'drop_timing',false);

    return {
        tripSheetByBoardingPoints:tripSheetByBoardingPoints,
        dropPointSeatNumber:sortedResultsByTiming,
        sharedSeatNumbers:sharedSeatNumbers
    };
}

const renderTicketsWithBoardingPoints=(tripSheet,showTripSheetInSingleTable)=>{

    let tripSheetColumns=[];

    let boardPointsComponent=[];
    let dropPointsComponent=null;
    let {
        tripSheetByBoardingPoints,
        dropPointSeatNumber,
        sharedSeatNumbers
    }=getTripSheetReportByBoardingPoint(tripSheet);
    let totalTickets = [];

    if(showColumn('serial_no')){
        tripSheetColumns.push({
            title : 'SN',
            key : 'index',
            dataIndex : 'index',
            className:"control-margin",
            render : (text, record, index) => index+1,
            order : getOrderForTripSheet('serial_no'),
            pdfRender: ({index}) => index+1
        });
    }
    if(showColumn('pnr')){
        tripSheetColumns.push({
            title : getTitleForTripSheet("PNR",'pnr'),
            key : 'pnr',
            dataIndex : 'pnr',
            className:"control-margin",
            render: (text, record) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:100}}>
                {text}
                </div>
            ),
            order : getOrderForTripSheet('pnr'),
            pdfRender: ({text}) => text,
            pdfWidth:'20%'
        });
    }

    if(showColumn('booking_time')){
        tripSheetColumns.push({
            title : getTitleForTripSheet("Booking time",'booking_time_title'),
            key : 'booking_time',
            dataIndex : 'booking_time',
            className:"control-margin",
            render : (text,record,index) =>(
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:100}}>
                    {getLocalDateAndTime(text)}
                </div>
            ),
            order : getOrderForTripSheet('booking_time'),
            pdfRender: ({text}) => getLocalDateAndTime(text),
            pdfWidth:'20%'
        });
    }

    if(showColumn('full_name')){
        tripSheetColumns.push({
            title : getTitleForTripSheet("Full Name",'full_name_title'),
            key : 'full_name',
            dataIndex : 'full_name',
            className:"control-margin tripSheetBold",
            render: (text, record) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:100,fontSize:16,fontWeight:"bold"}}>
                  {record.full_name}({record.gender})
                </div>
              ),
            order : getOrderForTripSheet('full_name'),
            pdfRender: ({record}) => `${record.full_name}(${record.gender})`,
            // pdfWidth:'20%'
        });
    }

    if(showColumn('age')){
        tripSheetColumns.push({
            title : getTitleForTripSheet("Age",'age_title'),
            key : 'age',
            dataIndex : 'age',
            className:"control-margin",
            order : getOrderForTripSheet('age'),
            pdfRender: ({text}) => text,
        });
    }

    if(showColumn('phone_number')){
        tripSheetColumns.push({
            title : getTitleForTripSheet("Phone",'phone_number'),
            key : 'phone_number',
            dataIndex : 'phone_number',
            className:"control-margin tripSheetBold",
            render: (text, record) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:105,fontSize:16,fontWeight:"bold"}}>
                    <a href={`tel:${text}`} className="tripSheetPhoneNumber">{text}</a>
                </div>
              ),
            order : getOrderForTripSheet('phone_number'),
            pdfRender: ({text}) =>{
                return(
                    <MobileCall txt={text}>{text}</MobileCall>
                )
            },
            pdfWidth:'20%'
        });
    }


    if(showColumn('agent_name')){
        tripSheetColumns.push({
            title : getTitleForTripSheet("Agent",'agent_name'),
            key : 'agent_name',
            dataIndex : 'agent_name',
            className:"control-margin",
            render: (text, record) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:100}}>
                  {text}
                </div>
              ),
            order : getOrderForTripSheet('agent_name'),
            pdfRender: ({text}) => `${text}`,
            // pdfWidth:'20%'
        });
    }


    if(showColumn('seat_number')){
        tripSheetColumns.push({
            title : getTitleForTripSheet("Seat#",'seat_number'),
            key : 'seat_number',
            dataIndex : 'seat_number',
            className:"control-margin",
            render: (text, record) => {
                if (sharedSeatNumbers[text]>1) {
                    return (
                        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', width:60}}>
                            <span>{text}</span>
                            <span className= "numberCircle"><span>{sharedSeatNumbers[text]}</span></span>
                        </div>
                    )
                }
                else {
                    return (
                        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:40}}>
                            {text}
                        </div>
                    )
                }
            },
            order : getOrderForTripSheet('seat_number'),
            pdfRender: ({text}) => {
                if (sharedSeatNumbers[text]>1) {
                    return (`${text} (${sharedSeatNumbers[text]})`)
                }
                else {
                    return (text)
                }
            },
        });
    }

        if(showColumn('ticket_cost')){
            tripSheetColumns.push({
                title:"Gross",
                key:"ticket_cost",
                dataIndex:'ticket_cost',
                className:"control-margin",
                render: (text, record) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:40}}>
                      {text}
                    </div>
                  ),
                order : getOrderForTripSheet('ticket_cost'),
                pdfRender: ({text}) => `${text}`
            });
        }


        if(showColumn('fare')){
            tripSheetColumns.push({
                title : getTitleForTripSheet("Fare",'fare'),
                key:'fare',
                dataIndex:'fare',
                className:"control-margin",
                render: (text, record) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:40}}>
                      {text}
                    </div>
                  ),
                order : getOrderForTripSheet('fare'),
                pdfRender: ({text}) => `${text}`
            });
        }

        if(showColumn('gst')){
            tripSheetColumns.push({
                title : getTitleForTripSheet("GST",'gst'),
                key:'gst',
                dataIndex:'gst',
                className:"control-margin",
                render: (text, record) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:40}}>
                      {text}
                    </div>
                  ),
                order : getOrderForTripSheet('gst'),
                pdfRender: ({text}) => `${text}`
            });
        }

        if(showColumn('commission')){
            tripSheetColumns.push({
                title : getTitleForTripSheet("Comm",'commission'),
                key:'commission',
                dataIndex:'commission',
                className:"control-margin",
                render: (text, record) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:50}}>
                      {text}
                    </div>
                  ),
                order : getOrderForTripSheet('commission'),
                pdfRender: ({text}) => `${text}`
            });
        }

        if(showColumn('amt')){
            tripSheetColumns.push({
                title : getTitleForTripSheet("Amt",'amt'),
                key : 'amt',
                dataIndex : 'amt',
                className:"control-margin",
                render : (text, record, index) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:50}}>
                        {roundNumber(text)}
                    </div>
                ),
                order : getOrderForTripSheet('amt'),
                pdfRender: ({text}) => `${roundNumber(text)}`
            })
        }

        if(showColumn('drop_city')){
            tripSheetColumns.push({
                title : getTitleForTripSheet("Destination",'drop_city'),
                key : 'drop_city',
                dataIndex : 'drop_city',
                className:"control-margin",
                order : getOrderForTripSheet('drop_city'),
                pdfRender: ({text}) => `${text}`,
                width : 100
                // pdfWidth:'20%'
            })
        }

        if(showColumn('pick_up_location')){
        tripSheetColumns.push({
            title : getTitleForTripSheet("Boarding",'pick_up_location_title'),
            key : 'location',
            dataIndex : 'location',
            className:"control-margin",
            order : getOrderForTripSheet('pick_up_location'),
            pdfRender: ({text}) => `${text}`,
            width : 200
            // pdfWidth:'20%'
            })
        }

        if(showColumn('drop_location')){
            tripSheetColumns.push({
                title : getTitleForTripSheet("Drop",'drop_location'),
                key : 'drop_location',
                dataIndex : 'drop_location',
                className:"control-margin",
                render : (text, record, index) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:100}}>
                        {text}
                    </div>
                ),
                order : getOrderForTripSheet('drop_location'),
                pdfRender: ({text}) => `${text}`,
                width : 200
                // pdfWidth:'20%'
            });
        }

        if(global.useRemarks === 1){
            tripSheetColumns.push({
                title : getTitleForTripSheet("Remarks",'remarks'),
                key : 'remarks',
                dataIndex : 'remarks',
                className:"control-margin",
                render : (text, record, index) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:80}}>
                        {text}
                    </div>
                ),
                order : getOrderForTripSheet('remarks'),
                pdfRender: ({text}) =>{
                    if(isValid(text)){
                        return text
                    }else{
                        return ''
                    }
                },
                // pdfWidth:'20%'
            });
        }


    if(isValidArray(tripSheetByBoardingPoints)){
        for (var key in tripSheetByBoardingPoints) {
            if (tripSheetByBoardingPoints.hasOwnProperty(key)) {
              var {
                boradingTickets,
                boardingTime,
                landmark
              } = tripSheetByBoardingPoints[key];
              if(isValidArray(boradingTickets)&&boradingTickets.length>0){
                if(!showTripSheetInSingleTable){
                    boardPointsComponent.push(<div className="marginTop">
                        <Row gutter={[16,16]}>
                            {isValid(key)&&isValid(boardingTime)&&key!=""&&
                            <Col>
                                <Text strong className="tripSheetSplFontSize">Boarding: {key}-{boardingTime}{isValid(landmark)?", Landmark: "+landmark:""}</Text>
                            </Col>
                            }
                            <Col>
                                <Text strong className="tripSheetSplFontSize">No of tickets({boradingTickets.length})</Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table 
                                    columns ={sortJsonObject(tripSheetColumns,'order',true)}
                                    dataSource ={boradingTickets}
                                    pagination={false}
                                    className={`bordered-table ${global.tripSheetBold}`}
                                    rowClassName={(record) => record.pnr == "Blocked" ? 'blocked-row' : '' }
                                />
                            </Col>
                        </Row>
                    </div>);
                }else{
                    totalTickets=totalTickets.concat(boradingTickets);
                }
              }
            }
        }
        if(showTripSheetInSingleTable){
            boardPointsComponent.push(<div className="marginTop">
                        <Row gutter={[16,16]}>
                            <Col>
                                <Text strong>No of tickets({totalTickets.length})</Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table 
                                    columns ={tripSheetColumns}
                                    dataSource ={totalTickets}
                                    pagination={false}
                                    className={`bordered-table ${global.tripSheetBold}`}
                                    // rowClassName={(record) => 
                                    //     (record.current_price !== record.previous_price ? "green" : (record.current_price === 'Timeout' ? "red" : null)
                                    // }
                                />
                            </Col>
                        </Row>
                    </div>)
        }
    }
    if(isValidArray(dropPointSeatNumber)){
        dropPointsComponent=<div className="marginTop">
                                <Row>
                                    <Col>
                                            <Text strong className="tripSheetSplFontSize">Drop Points Summary</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                            <Table 
                                            columns={dropPointsColumns}
                                            dataSource={dropPointSeatNumber}
                                            className="bordered-table tripSheetBold"
                                            pagination={false}
                                            />
                                    </Col>
                                </Row>
                            </div>
    }
    const agentTableColums=[
        {
            title:'SN',
            key:'index',
            className:"control-margin",
            render : (text, record, index) => {
                if(record.agentName == "Total"){
                    return null
                }else{
                    return index+1
                }
            },
            pdfRender: ({index}) => index+1
        },
        {
            title:'Agent Name',
            key:"agentName",
            className:"control-margin",
            dataIndex:'agentName',
            render : (text, record, index) => {
                if(record.agentName == "Total"){
                    return <Text strong>{text}</Text>
                }else{
                    return text
                }
            },
            pdfRender: ({text}) => text
        }
    ];

        agentTableColums.push({
            title:'Total Seats',
            key:'seatsCount',
            className:"control-margin",
            dataIndex:'seatsCount',
            render : (text, record, index) => {
                if(record.agentName == "Total"){
                    return <Text strong>{text}</Text>
                }else{
                    return text
                }
            },
            pdfRender: ({text}) => text
        });


        if(isShowSeatsColumn()){
            agentTableColums.push({
                title:'Seats',
                key:'seatNames',
                className:"control-margin seats-column-in-all-agents",
                dataIndex:'seatNames',
                pdfRender: ({text}) => text,
                render : (text, record, index) => {
                    if(record.agentName == "Total"){
                        return <Text strong>{text}</Text>
                    }else{
                        return text
                    }
                },
                pdfWidth : '40%'
            });
        }

        agentTableColums.push({
            title:'Fare',
            key:'fare',
            dataIndex : 'fare',
            className:"control-margin",
            render : (text, record, index) => {
                if(record.agentName == "Total"){
                    return <Text strong>{roundNumber(text)}</Text>
                }else{
                    return roundNumber(text)
                }
            },
            pdfRender: ({text}) => roundNumber(text)
        });

        agentTableColums.push({
            title:'Commission',
            key:'agentCommission',
            className:"control-margin",
            dataIndex : 'agentCommission',
            render : (text, record, index) => {
                if(record.agentName == "Total"){
                    return <Text strong>{roundNumber(text)}</Text>
                }else{
                    return roundNumber(text)
                }
            },
            pdfRender: ({text}) => roundNumber(text)
        });
        if(isShowTdsOnCommisionColumn()){
            agentTableColums.push({
                title:'Commission TDS',
                key:'tdsAgentCommission',
                className:"control-margin",
                dataIndex : 'tdsAgentCommission',
                render : (text, record, index) => {
                    if(record.agentName == "Total"){
                        return <Text strong>{roundNumber(text)}</Text>
                    }else{
                        return roundNumber(text)
                    }
                },                
                pdfRender: ({text}) => roundNumber(text)
            });
        }
        if(isShowNetCommisionColumn()){
            agentTableColums.push({
                title:'Net Commission',
                key:'tdsAgentCommission',
                className:"control-margin",
                dataIndex : 'tdsAgentCommission',
                render : (text, record, index) => {
                    if(record.agentName == "Total"){
                        return <Text strong>{roundNumber(record.agentCommission - record.tdsAgentCommission)}</Text>
                    }else{
                        return roundNumber(record.agentCommission - record.tdsAgentCommission)
                    }
                },
                pdfRender: ({record}) => roundNumber(record.agentCommission - record.tdsAgentCommission)
            });
        }
        agentTableColums.push({
            title:'Cancelled seats',
            key:'cancelledTicketsCount',
            className:"control-margin",
            dataIndex : 'cancelledTicketsCount',
            render : (text, record, index) => {
                if(record.agentName == "Total"){
                    return <Text strong>{roundNumber(text)}</Text>
                }else{
                    return roundNumber(text)
                }
            },            
            pdfRender: ({text}) => roundNumber(text)
        });
        agentTableColums.push({
            title:'Canellation Penality',
            key:'canellationPenality',
            className:"control-margin",
            dataIndex : 'canellationPenality',
            render : (text, record, index) => {
                if(record.agentName == "Total"){
                    return <Text strong>{roundNumber(text)}</Text>
                }else{
                    return roundNumber(text)
                }
            },
            pdfRender: ({text}) => roundNumber(text)
        });
        agentTableColums.push({
            title:'GST',
            key:'gst',
            className:"control-margin",
            dataIndex : 'gst',
            render : (text, record, index) => {
                if(record.agentName == "Total"){
                    return <Text strong>{roundNumber(text)}</Text>
                }else{
                    return roundNumber(text)
                }
            },
            pdfRender: ({text}) => roundNumber(text)
        });
        agentTableColums.push({
            title:'Net Cash',
            key:'amount',
            className:"control-margin",
            dataIndex : 'amount',
            render : (text, record, index) => {
                let amount = record.fare+record.canellationPenality-(record.agentCommission-record.tdsAgentCommission);
                if (record.privilege != 'apiAgentsInfo') {
                    amount+=record.gst;
                }
                if(record.agentName == "Total"){
                    return <Text strong>{roundNumber(text)}</Text>
                }else{
                    return roundNumber(amount)
                }
            },
            pdfRender: ({record}) => {
                let amount = record.fare+record.canellationPenality-(record.agentCommission-record.tdsAgentCommission);
                if (record.privilege !== 'apiAgentsInfo') {
                    amount+=record.gst;
                }
                return roundNumber(amount)
            }
        });

    return {
        boardPointsComponent:boardPointsComponent,
        dropPointsComponent:dropPointsComponent,
        dropPointsColumns:dropPointsColumns,
        tripSheetColumns:tripSheetColumns,
        tripSheetByBoardingPoints:tripSheetByBoardingPoints,
        dropPointsColumns:dropPointsColumns,
        dropPointSeatNumber:dropPointSeatNumber,
        agentTableColums:agentTableColums
    };
}


const getBookedSeatsCount=(tripSheet)=>{
    if(isValidArray(tripSheet)&&tripSheet.length>0){
        return tripSheet.length;
    }else{
        return 0
    }
}

const getAvailableSeats=(avaliableSeats)=>{
    if(isValidArray(avaliableSeats)&&avaliableSeats.length>0){
        return avaliableSeats.length;
    }else{
        return 0
    }
}

const {
    Text
} = Typography;

let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const getSchedule=(schedules,tripId)=>{
    let selectedSchedule=null;
    if(isValidArray(schedules)&&schedules.length>0){
        schedules.forEach(element => {
            if(element.trip_id===tripId){
                selectedSchedule=element;
            }
        });
    }
    return selectedSchedule
}


const avaliableSeatsColumns=[
    {
        title:'SN',
        key:'index',
        render : (text, record, index) => index+1,
        className:"control-margin",
        pdfRender: ({index}) => index+1
    },
    {
        title:"Seat No",
        key:'seat_number',
        className:"control-margin",
        dataIndex:'seat_number',
        pdfRender: ({text}) => text
    },
    {
        title:"Ticket no",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    },
    {
        title:"Passenger Name",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    },
    {
        title:"Phone No",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    },
    {
        title:"Boarding",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    },
    {
        title:"Destination",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    },
    {
        title:"Drop off",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    },
    {
        title:"Gross",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    },
    {
        title:"Agent comm",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    },
    {
        title:"Amount",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    },
    {
        title:"Issued by",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    },
    {
        title:"Remarks",
        key:'index',
        className:"control-margin",
        pdfRender: ({text}) => text
    }
];

const isRenderAvailableSeatsShow=()=>{
    let isAvoidAvailableSeats = false;
    if(isValidArray(tripSheetReportConfig[global.operatorCode])){
        if(tripSheetReportConfig[global.operatorCode]["tablesToAvoid"].indexOf(AVAIABLE_SEATS)!=-1){
            isAvoidAvailableSeats=true;
        }
    }
    return isAvoidAvailableSeats;
}
const renderAvailableSeatsTable=({avaliableSeats})=>{
   
    if(isRenderAvailableSeatsShow()){
        return null;
    }
    return (<div className="marginTop">
                    <Row className="marginTop" justify="center">
                        <Col>
                                <Text strong className="tripSheetSplFontSize">Avaliable Seats</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                columns={avaliableSeatsColumns}
                                dataSource={avaliableSeats}
                                pagination={false}
                                className={`bordered-table ${global.tripSheetBold}`}
                            />
                        </Col>
                    </Row>
                </div>);
};
const isRenderAdminBlockedAvailableSeats=()=>{
    let isAvoidAvailableSeats = false;
    if(isValidArray(tripSheetReportConfig[global.operatorCode])){
        if(tripSheetReportConfig[global.operatorCode]["tablesToAvoid"].indexOf(ADMIN_BLOCKED_SEATS)!=-1){
            isAvoidAvailableSeats=true;
        }
    }
    return isAvoidAvailableSeats;
}


const renderAdminBlockedSeatsTable=({adminBlockedSeats})=>{

    if(isRenderAdminBlockedAvailableSeats()){
        return null;
    }
    return (<div className="marginTop">
                    <Row className="marginTop" justify="center">
                        <Col>
                                <Text strong>Admin Blocked Seats</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                
                                columns={avaliableSeatsColumns}
                                dataSource={adminBlockedSeats}
                                pagination={false}
                                className="bordered-table"
                            />
                        </Col>
                    </Row>
                </div>);
};


const isShowSeatsColumn =()=>{
    if(isValidArray(tripSheetReportConfig[global.operatorCode])&&tripSheetReportConfig[global.operatorCode].allAgentsTable.showSeats === false){
        return false;
    }else{
        return true;
    }
}

// const isShowSeatsCountColumn =()=>{
//     if(isValidArray(tripSheetReportConfig[global.operatorCode])&&tripSheetReportConfig[global.operatorCode].allAgentsTable.showSeatsCount === true){
//         return true;
//     }else{
//         return false;
//     }
// }

const showAllAgentsTable=()=>{
    if(
        isValidArray(tripSheetReportConfig[global.operatorCode])&&
        isValid(tripSheetReportConfig[global.operatorCode].tripSheet)
        ){
            if(tripSheetReportConfig[global.operatorCode].tripSheet.isAgentSpecific === true){
                let agentSpecificPermissions = tripSheetReportConfig[global.operatorCode].tripSheet.agentSpecificPermissions;
                let bookingAgents = tripSheetReportConfig[global.operatorCode].tripSheet.agentSpecificPermissions.bookingAgents;
                if(isValidArray(agentSpecificPermissions)&&bookingAgents.indexOf(global.privilege) !== -1){
                    let tables = tripSheetReportConfig[global.operatorCode].tripSheet.agentSpecificPermissions.tables;
                    if(isValidArray(tables)){
                        let isShowAllAgentsTable =  tripSheetReportConfig[global.operatorCode].tripSheet.agentSpecificPermissions.tables.isShowAllAgentsTable;
                        return isShowAllAgentsTable;
                    }else{
                        return true;
                    }
                }else{
                    return true;
                }
            }
        return true;
    }else{
        return true;
    }
}

const isShowTdsOnCommisionColumn =()=>{
    if(isValidArray(tripSheetReportConfig[global.operatorCode])&&tripSheetReportConfig[global.operatorCode].allAgentsTable.showTdsOnCommision === true){
        return true;
    }else{
        return false;
    }
}

const isShowNetCommisionColumn =()=>{
    if(isValidArray(tripSheetReportConfig[global.operatorCode])&&tripSheetReportConfig[global.operatorCode].allAgentsTable.showNetCommision === true){
        return true;
    }else{
        return false;
    }
}
const RenderReport=({
    showTripSheetInSingleTable,
    notBoardedSeats,showSpecificAgent,selectedSchedule,tripSheet,bookingInfo,vechicleInfo,isPrinter,avaliableSeats,adminBlockedSeats})=>{

    if(isValidArray(selectedSchedule)&&isValid(selectedSchedule.id)&&isValidArray(tripSheet)){
        let bookedSeats=getBookedSeatsCount(tripSheet);
        let {
            boardPointsComponent,
            dropPointsComponent,
            agentTableColums
        }=renderTicketsWithBoardingPoints(tripSheet,showTripSheetInSingleTable)

        return(
            <div>
                <div className="tripSheetSplFontSize">
                    {isPrinter&&
                    <Row gutter={[16,0]} justify="center">
                        <Col>
                            <RenderLogo
                                logoUrl={global.logoUrl}
                                operatorName={global.operatorName}
                            />
                        </Col>
                    </Row>
                    }
                    {isPrinter&&
                        <Row gutter={[16,0]} justify="center">
                        <Col>
                            <Text strong>{global.operatorName}</Text>
                        </Col>
                        </Row>
                    }
                    {!isPrinter&&
                    <Row gutter={[16,0]} justify="center">
                        <Col>
                            {renderCity()}
                        </Col>
                    </Row>
                    }
                    <Row gutter={[16,0]} justify="center">
                        <Col>
                            <Text strong>
                            Trip Sheet - {global.userName}
                            </Text>
                        </Col>
                    </Row>
                    <Row gutter={[16,0]} justify="space-between">
                        <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Trip Name : </Text>{selectedSchedule.description}
                            </Text>
                        </Col>
                    </Row>
                    <Row gutter={[16,0]} justify="space-between">
                        <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>From : </Text>{selectedSchedule.origin}
                            </Text>
                        </Col>
                        <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Travel Date : </Text>{getLocalDate(convertDateStringToDate(selectedSchedule.trip.start_date.split('T')[0]))}
                            </Text>
                        </Col>
                    </Row>
                    <Row gutter={[16,0]} justify="space-between">
                        <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>To : </Text>{selectedSchedule.destination}
                            </Text>
                        </Col>
                        <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Dep Time : </Text>{getLocalTime(convertTimeStringToDate(selectedSchedule.start_time))}
                            </Text>
                        </Col>
                    </Row>
                    <Row gutter={[16,0]} justify="space-between">
                        <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Vehicle # : </Text>{vechicleInfo.vechicle_no}
                            </Text>
                        </Col>
                    </Row>
                    <Row gutter={[16,0]} justify="space-between">
                        {isValid(vechicleInfo.driver_name)&&
                        <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Driver Name : </Text>{vechicleInfo.driver_name}
                            </Text>
                        </Col>
                        }
                        <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Driver phone # : </Text>{vechicleInfo.phone_no}
                            </Text>
                        </Col>
                    </Row>
                    <Row gutter={[16,0]} justify="space-between">
                        {isValid(vechicleInfo.second_driver_name)&&
                            <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                <Text>
                                    <Text strong>Second Driver Name : </Text>{vechicleInfo.second_driver_name}
                                </Text>
                            </Col>
                        }
                        {isValid(vechicleInfo.second_driver_phone_no)&&
                            <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                <Text>
                                    <Text strong>Second Driver phone # : </Text>{vechicleInfo.second_driver_phone_no}
                                </Text>
                            </Col>
                        }
                    </Row>
                    <Row gutter={[16,0]} justify="space-between">
                        <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>No of booked seats : </Text>{bookedSeats}
                            </Text>
                        </Col>
                        <Col xs={isPrinter?12:xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Vacant Seats : </Text>{getAvailableSeats(avaliableSeats)}
                            </Text>
                        </Col>
                    </Row>
                    <Row gutter={[16,0]}>
                        <Col>
                            <Text>
                                <Text strong>Generated day/time : </Text>{getLocalDateAndTime(new Date())}
                            </Text>
                        </Col>
                    </Row>
            </div>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    {boardPointsComponent}
                </Col>
            </Row>
            <Row className="marginTop" justify="center">
                <Col>
                        <Text strong className="tripSheetSplFontSize">Summary</Text>
                </Col>
            </Row>
            {renderAvailableSeatsTable({avaliableSeats})}
            {renderAdminBlockedSeatsTable({adminBlockedSeats})}
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <CancelledTripSheet
                    tripId={selectedSchedule.trip_id}
                    />
                </Col>
            </Row>
            {isValidArray(notBoardedSeats)&&notBoardedSeats.length>0&&
                <div>
                     <Row className="marginTop">
                        <Col>
                                <Text strong className="tripSheetSplFontSize">Not Boarded Seats</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table 
                                className={`bordered-table ${global.tripSheetBold}`}
                                dataSource={notBoardedSeats}
                                columns={notBoardedColumns}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                </div>
            }
            {showAllAgentsTable()&&
            <div>
                {!showSpecificAgent?
                    <Row className="marginTop" justify="center">
                        <Col>
                                <Text strong className="tripSheetSplFontSize">All Agents</Text>
                        </Col>
                    </Row>
                    :
                    <Row className="marginTop">
                        <Col>
                                <Text strong className="tripSheetSplFontSize">Agent info</Text>
                        </Col>
                    </Row>
                    }
                <Table 
                    className={`bordered-table ${global.tripSheetBold}`}
                    columns={agentTableColums}
                    dataSource={getAgentsInfo(bookingInfo,showSpecificAgent)}
                    pagination={false}
                />
            </div>
            }
            {dropPointsComponent}
        </div>
        )
    }else{
        return null
    }

}

export const TripSheetReport = ({
    showVechicleWarn,
    showInModal=false,
    showPrintIcon,
    tripId,
    intialSchedules,
    intialSelectedSchedule,
    toolTipText
}) => {

    const [schedules,setSchedules]=useState(intialSchedules);
    const [selectedSchedule,setSelectedSchedule]=useState(intialSelectedSchedule);
    const [tripSheet,setTripSheet]=useState(undefined);
    const [vechicleInfo,setVechicleInfo]=useState({});
    const [bookingInfo,setBookingInfo]=useState(undefined);
    const [ showReport , setShowReport]=useState(false);
    const [ avaliableSeats , setAvailableSeats]=useState(undefined);
    const [ adminBlockedSeats , setAdminBlockedSeats]=useState(undefined);
    const [ notBoardedSeats , setNotBoardedSeats]=useState(undefined);
    const [ isModalVisible , setIsModalVisible]=useState(false);

    let showTripSheetInSingleTable = showTableAsSingleTable();

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onSuccess=(data)=>{
        setLoading(false);
        setTripSheet(data.tripSheet);
        setVechicleInfo(data.vechicleInfo);
        setBookingInfo(data.bookingInfo);
        setAvailableSeats(data.avaliableSeats);
        setAdminBlockedSeats(data.adminBlockedSeats);
        setNotBoardedSeats(data.notBoardedSeats);
        setShowReport(true);
    }

    const onSearch=(data)=>{
        form.setFieldsValue({'returnId':null});
        setSelectedSchedule(null);
        setTripSheet(null);
        setSchedules(data);
    }

    const fetchReport = async(id)=>{
        setLoading(true);
        return await fetchTripSheet(id,onSuccess);
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    let showSpecificAgent = isValidArray(tripSheetReportConfig[global.operatorCode])&&tripSheetReportConfig[global.operatorCode].allAgentsTable.showOnlySpecificAgent === true?true:false;


    if(showInModal){
        return (
          <div>
               <Button ghost
               onClick={async (onPrint)=>{
                    if(isValid(tripId)){
                      await fetchReport(tripId);
                    }
                    setIsModalVisible(true);
                }}
               className="open-select-seat-btn">
                    <Icon component={() => (<img src={PrintIcon} style={{width:'22px',padding:'1px'}} />)} />
                </Button>
              <Modal
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              style={{minWidth:'1400px'}}
              >
                <div style={{marginBottom:'10px'}}>
                    <Row gutter={[16,16]}>
                        <Col>
                            <TripSheetPdf 
                                tripSheet={tripSheet}
                                selectedSchedule={selectedSchedule}
                                bookingInfo={bookingInfo}
                                vechicleInfo={vechicleInfo}
                                avaliableSeats={avaliableSeats}
                                adminBlockedSeats={adminBlockedSeats}   
                                notBoardedSeats={notBoardedSeats}
                                operatorCity={renderCity()}
                                bookedSeatsCount={getBookedSeatsCount(tripSheet)}
                                vacantSeatCount={getAvailableSeats(avaliableSeats)}
                                {...renderTicketsWithBoardingPoints(tripSheet)}
                                avaliableSeatsColumns={avaliableSeatsColumns}
                                isRenderAdminBlockedAvailableSeats={!isRenderAdminBlockedAvailableSeats()}
                                isRenderAvailableSeatsShow={!isRenderAvailableSeatsShow()}
                                showAllAgentsTable={showAllAgentsTable()}
                                agentsTableData={getAgentsInfo(bookingInfo,showSpecificAgent)}
                                notBoardedColumns={notBoardedColumns}
                                showTripSheetInSingleTable={showTripSheetInSingleTable}
                            />
                        </Col>
                        <Col>
                            <MiniTripSheetPdf 
                                tripSheet={tripSheet}
                                selectedSchedule={selectedSchedule}
                                bookingInfo={bookingInfo}
                                vechicleInfo={vechicleInfo}
                                avaliableSeats={avaliableSeats}
                                adminBlockedSeats={adminBlockedSeats}   
                                notBoardedSeats={notBoardedSeats}
                                operatorCity={renderCity()}
                                bookedSeatsCount={getBookedSeatsCount(tripSheet)}
                                vacantSeatCount={getAvailableSeats(avaliableSeats)}
                                {...renderTicketsWithBoardingPoints(tripSheet)}
                                avaliableSeatsColumns={avaliableSeatsColumns}
                                isRenderAdminBlockedAvailableSeats={!isRenderAdminBlockedAvailableSeats()}
                                isRenderAvailableSeatsShow={!isRenderAvailableSeatsShow()}
                                showAllAgentsTable={showAllAgentsTable()}
                                agentsTableData={getAgentsInfo(bookingInfo,showSpecificAgent)}
                                notBoardedColumns={notBoardedColumns}
                            />
                        </Col>
                        <Col>
                            <RTOTripSheet
                                tripSheet={tripSheet}
                                selectedSchedule={selectedSchedule}
                                bookingInfo={bookingInfo}
                                vechicleInfo={vechicleInfo}
                                avaliableSeats={avaliableSeats}
                                adminBlockedSeats={adminBlockedSeats}
                                notBoardedSeats={notBoardedSeats}
                                operatorCity={renderCity()}
                                bookedSeatsCount={getBookedSeatsCount(tripSheet)}
                                vacantSeatCount={getAvailableSeats(avaliableSeats)}
                                {...renderTicketsWithBoardingPoints(tripSheet)}
                                avaliableSeatsColumns={avaliableSeatsColumns}
                                isRenderAdminBlockedAvailableSeats={!isRenderAdminBlockedAvailableSeats()}
                                isRenderAvailableSeatsShow={!isRenderAvailableSeatsShow()}
                                showAllAgentsTable={showAllAgentsTable()}
                                agentsTableData={getAgentsInfo(bookingInfo,showSpecificAgent)}
                                notBoardedColumns={notBoardedColumns}
                                showTripSheetInSingleTable={showTripSheetInSingleTable}
                                showColumn = {showColumn}
                            />
                        </Col>
                        <Col>
                            <TicketExcelReport 
                                tripSheet={tripSheet}   
                            />
                        </Col>
                    </Row>
                </div>
                <Printer
                    showPrintIcon={showPrintIcon}
                    showComponent={true}
                    zoomValue={1}
                    toolTipText={toolTipText}
                    checkValidation={async (onPrint)=>{
                        if(isValid(tripId)){
                            await fetchReport(tripId);
                        }
                        if((showVechicleWarn)||(isValidArray(vechicleInfo)&&isValid(vechicleInfo.phone_no)&&isValid(vechicleInfo.vechicle_no))){
                            onPrint();
                        }else{
                            let description = "Missing Driver Phone & Bus Number ";
                            if(!isValid(vechicleInfo.phone_no)&&isValid(vechicleInfo.vechicle_no)){
                            description = "Missing Driver Phone";
                            }
                            if(isValid(vechicleInfo.phone_no)&&!isValid(vechicleInfo.vechicle_no)){
                                description = "Missing Bus Number";
                            }
                            return showConfirm(()=>{
                                onPrint()
                            },"Warning",description);
                        }
                    }}
                    renderComponent={()=>(
                        <RenderReport
                        showSpecificAgent={showSpecificAgent}
                        tripSheet={tripSheet}
                        selectedSchedule={selectedSchedule}
                        bookingInfo={bookingInfo}
                        vechicleInfo={vechicleInfo}
                        isPrinter={true}
                        avaliableSeats={avaliableSeats}
                        adminBlockedSeats={adminBlockedSeats}
                        notBoardedSeats={notBoardedSeats}
                        showTripSheetInSingleTable={showTripSheetInSingleTable}
                        />
                    )}/>
              </Modal>
          </div>
        )
    }else{
        return (
            <div className="report-inner-div">
                <SearchScheduleComponent
                    onSearch={onSearch}
                />
                {isValidArray(schedules)&&schedules.length>0&&
                 <Form
                 form={form}
                 onFinish={(data)=>{
                     let {
                        returnId
                     }=data;
                     setShowReport(false);
                     setLoading(true);
                     setTripSheet(undefined);
                     setSelectedSchedule(getSchedule(schedules,returnId));
                     fetchReport(returnId);
                 }}
                 >
                    <Row gutter={16}>
                        <Col>
                            <ScheduleSelect
                               schedules={schedules}
                               optionKey="trip_id"
                               isTripSheetPermissionCheck={true}
                            />
                        </Col>
                        <Col>
                            <Form.Item>
                                    <Button type="primary" htmlType="submit">Get</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                 </Form>
                }
                {showReport&&
                <div>
                    <Printer
                        showComponent={false}
                        zoomValue={1}
                        checkValidation={(onPrint)=>{
                            if(isValidArray(vechicleInfo)&&isValid(vechicleInfo.phone_no)&&isValid(vechicleInfo.vechicle_no)){
                                onPrint();
                            }else{
                                let description = "Missing Driver Phone & Bus Number ";
                                if(!isValid(vechicleInfo.phone_no)&&isValid(vechicleInfo.vechicle_no)){
                                  description = "Missing Driver Phone";
                                }
                                if(isValid(vechicleInfo.phone_no)&&!isValid(vechicleInfo.vechicle_no)){
                                    description = "Missing Bus Number";
                                  }
                                return showConfirm(()=>{
                                    onPrint()
                                },"Warning",description);
                            }
                        }}
                        renderComponent={()=>(
                            <RenderReport
                            showSpecificAgent={showSpecificAgent}
                            tripSheet={tripSheet}
                            selectedSchedule={selectedSchedule}
                            bookingInfo={bookingInfo}
                            vechicleInfo={vechicleInfo}
                            isPrinter={true}
                            avaliableSeats={avaliableSeats}
                            adminBlockedSeats={adminBlockedSeats}
                            notBoardedSeats={notBoardedSeats}
                            showTripSheetInSingleTable={showTripSheetInSingleTable}
                            />
                        )}/>
                        <Row gutter={[16,16]}>
                            <Col>
                                <TripSheetPdf 
                                    tripSheet={tripSheet}
                                    selectedSchedule={selectedSchedule}
                                    bookingInfo={bookingInfo}
                                    vechicleInfo={vechicleInfo}
                                    avaliableSeats={avaliableSeats}
                                    adminBlockedSeats={adminBlockedSeats}   
                                    notBoardedSeats={notBoardedSeats}
                                    operatorCity={renderCity()}
                                    bookedSeatsCount={getBookedSeatsCount(tripSheet)}
                                    vacantSeatCount={getAvailableSeats(avaliableSeats)}
                                    {...renderTicketsWithBoardingPoints(tripSheet)}
                                    avaliableSeatsColumns={avaliableSeatsColumns}
                                    isRenderAdminBlockedAvailableSeats={!isRenderAdminBlockedAvailableSeats()}
                                    isRenderAvailableSeatsShow={!isRenderAvailableSeatsShow()}
                                    showAllAgentsTable={showAllAgentsTable()}
                                    agentsTableData={getAgentsInfo(bookingInfo,showSpecificAgent)}
                                    notBoardedColumns={notBoardedColumns}
                                />
                            </Col>
                            <Col>
                                <MiniTripSheetPdf 
                                    tripSheet={tripSheet}
                                    selectedSchedule={selectedSchedule}
                                    bookingInfo={bookingInfo}
                                    vechicleInfo={vechicleInfo}
                                    avaliableSeats={avaliableSeats}
                                    adminBlockedSeats={adminBlockedSeats}   
                                    notBoardedSeats={notBoardedSeats}
                                    operatorCity={renderCity()}
                                    bookedSeatsCount={getBookedSeatsCount(tripSheet)}
                                    vacantSeatCount={getAvailableSeats(avaliableSeats)}
                                    {...renderTicketsWithBoardingPoints(tripSheet)}
                                    avaliableSeatsColumns={avaliableSeatsColumns}
                                    isRenderAdminBlockedAvailableSeats={!isRenderAdminBlockedAvailableSeats()}
                                    isRenderAvailableSeatsShow={!isRenderAvailableSeatsShow()}
                                    showAllAgentsTable={showAllAgentsTable()}
                                    agentsTableData={getAgentsInfo(bookingInfo,showSpecificAgent)}
                                    notBoardedColumns={notBoardedColumns}
                                />
                            </Col>
                            <Col>
                                <RTOTripSheet
                                    tripSheet={tripSheet}
                                    selectedSchedule={selectedSchedule}
                                    bookingInfo={bookingInfo}
                                    vechicleInfo={vechicleInfo}
                                    avaliableSeats={avaliableSeats}
                                    adminBlockedSeats={adminBlockedSeats}
                                    notBoardedSeats={notBoardedSeats}
                                    operatorCity={renderCity()}
                                    bookedSeatsCount={getBookedSeatsCount(tripSheet)}
                                    vacantSeatCount={getAvailableSeats(avaliableSeats)}
                                    {...renderTicketsWithBoardingPoints(tripSheet)}
                                    avaliableSeatsColumns={avaliableSeatsColumns}
                                    isRenderAdminBlockedAvailableSeats={!isRenderAdminBlockedAvailableSeats()}
                                    isRenderAvailableSeatsShow={!isRenderAvailableSeatsShow()}
                                    showAllAgentsTable={showAllAgentsTable()}
                                    agentsTableData={getAgentsInfo(bookingInfo,showSpecificAgent)}
                                    notBoardedColumns={notBoardedColumns}
                                    showTripSheetInSingleTable={showTripSheetInSingleTable}
                                    showColumn = {showColumn}
                                />
                            </Col>
                            <Col>
                                <TicketExcelReport 
                                    tripSheet={tripSheet}   
                                />
                            </Col>
                        </Row>
                    <RenderReport
                        tripSheet={tripSheet}
                        selectedSchedule={selectedSchedule}
                        bookingInfo={bookingInfo}
                        vechicleInfo={vechicleInfo}
                        avaliableSeats={avaliableSeats}
                        adminBlockedSeats={adminBlockedSeats}
                        showSpecificAgent={showSpecificAgent}
                        notBoardedSeats={notBoardedSeats}
                        showTripSheetInSingleTable={showTripSheetInSingleTable}
                        />
                </div>
                }
            </div>)
    }
}
