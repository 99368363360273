import React from "react";
import { Progress } from 'antd';
import '../styles/trip-create-success.style.scss';

export const TripCreateSuccessPage = () => {

    return (
        <div className="trip-create-success-root">
            <div className="trip-create-success-inner-div">
                <div className="circle-block">
                    <Progress type="circle" percent={100} />
                </div>
                <div className="text-block">
                    <h3>Trip successfully created.</h3>
                </div>
            </div>
        </div>
    )
}