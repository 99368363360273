/**
 * @author Rejo Varghese
 * @description Module related to ticket search
 */

import React from 'react'
import { isValidArray, checkActionAllowed } from '../../utils/utilities';
import { roundNumber, getPercentage } from '../../utils/math.utils';
import { ACTION_SEND_CANCEL_NOTIFICATION_ALLOWED, ACTION_OVERRIDE_CANCELLATION_PENALTY_ALLOWED } from '../../utils/constant';
import { Row, Col, InputNumber, Checkbox } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';

export const getSelectedTravellerInfo=(selectedTravellers)=>{
    let totalTicketCost=0,totalCancellationPenalty=0,totalRefundAmount=0,fare=0 , totalAmountPaid = 0 ;
    if(isValidArray(selectedTravellers)&&selectedTravellers.length>0){
        selectedTravellers.forEach(element => {
                totalTicketCost = totalTicketCost + element.amount_paid;
                totalCancellationPenalty = totalCancellationPenalty + element.cancellation_penalty;
                if(element.cancellation_penalty == element.amount_paid){
                    fare = fare + element.amount_paid;
                }else{
                    fare = fare + element.discounted_fare;
                }
                totalAmountPaid = totalAmountPaid + element.amount_paid;
        });
        totalRefundAmount = totalRefundAmount +(totalTicketCost-totalCancellationPenalty)
    }

    return{
        totalTicketCost:roundNumber(totalTicketCost),
        totalCancellationPenalty:roundNumber(totalCancellationPenalty),
        totalRefundAmount:roundNumber(totalRefundAmount),
        totalCancellationPenaltyPercentage :roundNumber(getPercentage(totalCancellationPenalty,fare)),
        totalFare : roundNumber(fare),
        totalAmountPaid : totalAmountPaid
    }
}

export const getFormatedTravells=(travellers)=>{
    let formatedTrallers=[];
    if(isValidArray(travellers)&&travellers.length>0){
        travellers.forEach((traveller)=>{
            if(isValidArray(traveller)){
                formatedTrallers.push({
                    "id":traveller.id,
                    "fullName":traveller.full_name,
                    "gender":traveller.gender,
                    "age":traveller.age,"pickup_location":traveller.pickup_location,"seatNumber":traveller.seat_number
                }) 
            }
        })
    }
    return formatedTrallers;
}

export const CancelAlertBody=({totalCancellationPenalty,sendNotification,setSendNotification,setCancellationPenality,cancellationPenality,isNotBoardedBody=false})=>{

    let isSendNotificationShow = checkActionAllowed(ACTION_SEND_CANCEL_NOTIFICATION_ALLOWED);
    let isWaiveCancellationPenality = checkActionAllowed(ACTION_OVERRIDE_CANCELLATION_PENALTY_ALLOWED);
    return(
        <div>   
            <Row>
                {isNotBoardedBody?
                <Col>Do you want to mark ticket as not boarded?</Col>
                :
                <Col>Do you want to cancel the ticket?</Col>
                }
            </Row>
            {isSendNotificationShow&&!isNotBoardedBody&&
                <Row>
                    <Col>Send Notification:
                    <Checkbox checked={sendNotification} onChange={(e)=>{
                            setSendNotification(e.target.checked);
                    }} /></Col>
                </Row>
            }
            {isWaiveCancellationPenality&&!isNotBoardedBody&&
                <Row gutter={[8,8]} align="middle">
                    <Col justify="center" align="center">Cancellation Penalty Percentage</Col>
                    <Col justify="center" align="center">
                        <InputNumber 
                            value={cancellationPenality}
                            min={0}
                            max={100}
                            onChange={(value)=>{
                                setCancellationPenality(value);
                            }}
                            className="percentageInput"
                        />
                    </Col>
                    <Col justify="center" align="center">
                        <PercentageOutlined  />
                    </Col>
               </Row>
            }
            <Row>
                <Col>You will be charged a penalty of Rs.{totalCancellationPenalty}</Col>
            </Row>
        </div>
    )
}
