import React, { useState } from 'react';
import { isValidNumber  } from '../../utils/utilities';
import './donut-chart.style.scss';
import { Pie } from '@ant-design/plots';

const DonutChartComponent = (props) => {

    const {
      AdminBlocked,
      Blocked,
      Booked,
      Vacant
    }=props;
    let data=[] , color=[];

    const [centerTitle , setCenteredTitle] = useState(Vacant);
    const [centerContent , setCenteredContent] = useState("Seats");

    if(isValidNumber(AdminBlocked)&&AdminBlocked>0){
      data.push({
        type: 'Admin Blocked',
        value: AdminBlocked,
        // angleField: "Admin Blocked("+AdminBlocked+")",
        "Admin Blocked": AdminBlocked
      })
      color.push('#479FF8');
    }
    if(isValidNumber(Booked)&&Booked>0){
      data.push({
        type: 'Booked',
        value: Booked,
        // angleField: "Booked("+Booked+")",
        "Booked": Booked
      })
      color.push('#81D653');
    }
    if(isValidNumber(Blocked)&&Blocked>0){
      data.push({
        type: 'Blocked',
        value: Blocked,
        // angleField: "Blocked("+Blocked+")",
        "Blocked": Blocked
      })
      color.push('#EFBD40');
    }
    if(isValidNumber(Vacant)&&Vacant>0){
      data.push({
        type: 'Vacant',
        value: Vacant,
        // angleField: "Vacant("+Vacant+")",  
        "Vacant": Vacant
      })
      color.push('#EA4025');
    }
    
    return (
        <div style={{marginTop:-20}}>
            <Pie
                label={{
                  visible:true,
                  autoRotate:true,
                  formatter:(value)=>{
                    return value.angleField
                  },
                  style:{fontSize: 8, fill: 'black'}
                }}
                data={data}
                color={color}
                height={90}
                width={135}
                legend={{
                    visible:false
                }}
                title={{
                    visible: false,
                    text: '环图',
                }}
                description={{
                    visible: false,
                    text: '环图的外半径决定环图的大小，而内半径决定环图的厚度。',
                }}
                radius={0.9}
                innerRadius ={0.55}
                padding='auto'
                angleField='value'
                colorField='type'
                tooltip={{
                  visible:true,
                  formatter	:(info)=>{
                    return {name:info.type,value:info.value};
                  }
                }}
                autoFit
                statistic={{
                    title:{
                      customHtml:()=>{
                        return `${centerTitle}`
                      },
                      style:{fontSize: 18, fill: 'red',fontWeight:'bold'}
                    },
                    content:{
                      customHtml:()=>{
                        return centerContent
                      },
                      style:{fontSize: 10, fill: 'red',fontWeight:'bold'}
                    }
                }}
                events={{
                      // onRingClick: (event) => console.log(event),
                      // onLegendClick: (event) => console.log(event),
                      onPlotClick: (event) => {
                        // if(isValidArray(event.data)&&isValidArray(event.data.data)&&isValid(event.data.data.value)){
                        //   setCenteredTitle(event.data.data.value);
                        // }
                      }
                  }}
                />
        </div>
    );
}

function arePropsEqual(prevProps, nextProps) {
  return (
      prevProps.AdminBlocked === nextProps.AdminBlocked || 
      prevProps.Blocked === nextProps.Blocked || 
      prevProps.Booked === nextProps.Booked ||
      prevProps.Vacant === nextProps.Vacant
    ); 
}

export default React.memo(DonutChartComponent,arePropsEqual);
