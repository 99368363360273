import moment from 'moment';
import { getReadableDate , incrementDays } from '../utils/date.utils';

export const getFormatedDashBoardData = (dashboardData,currentDate)=>{

  var a = getReadableDate(currentDate);
  var b = getReadableDate(incrementDays(currentDate,1));
  var c = getReadableDate(incrementDays(currentDate,2));

  let formateData=[];

  let origin="",destination="";

  let originCities=[],destinationCities =[];

 

  for (var i = 0; i < dashboardData.length; i++) {

    if(dashboardData[i].trips!=undefined){

      if((dashboardData[i].origin+dashboardData[i].destination)!=(origin+destination)){

        origin=dashboardData[i].origin;

        destination=dashboardData[i].destination;

        var description ={description:{origin:origin,destination:destination},origin:origin,destination:destination};

        formateData.push(description);

  

        if(originCities.indexOf(origin)==-1){

          originCities.push(origin);

        }

  

        if(destinationCities.indexOf(destination)==-1){

          destinationCities.push(destination);

        }

      }

        let row={

          description:dashboardData[i].description,
          origin:origin,
          destination:destination
        }

        for (var j = 0; j < dashboardData[i].trips.length; j++) {

          let cur_trip=dashboardData[i].trips[j];

          var curr_date=new Date(dashboardData[i].trips[j].start_date);

          var curr_date_formate=getReadableDate(moment(curr_date),false,true);

          var attribute=(curr_date_formate==a?"day1":
          
                        (curr_date_formate==b?"day2":

                        (curr_date_formate==c?"day3":"invalidDate")));

          row[attribute]={

            Vacant:cur_trip.available_seats,

            Admin_Blocked:cur_trip.blocked_by_admin,

            Blocked:cur_trip.blocked_seats,

            Booked:cur_trip.booked_seats,

            basefare:cur_trip.ticket_cost

    
          };

        }

        formateData.push(row);

        }
      }

      return {

        formateData:formateData,

        originCities:originCities,

        destinationCities:destinationCities
      }
  }