/**
 * @author Manne Hareesh
 * @description Reducer for the components in Revenue page
 */
import {
    RESET_ALL,
    SET_REVENUE_REPORT
 } from '../actions/action-types';
  
  const initialState = {
      revenueDetails:[],
  }
  
  export const revenueReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {  

         case SET_REVENUE_REPORT:{
            let {
                revenueDetails   
            } = action.payload;
            newState["revenueDetails"]= revenueDetails;            
            return newState;
         }
          
        case RESET_ALL :
            newState["revenueDetails"] = [];
            return newState;
  
          default:
              return state;
      }
  }
  