import { message } from 'antd';
import { 
  GET_TICKET_BY_ID_URL , 
  CANCEL_TICKET_URL , 
  UPDATE_TICKET_URL, 
  OVER_RIDE_PENALITY_URL,
  SEND_UPDATED_TICKET_MAIL_URL, 
  SEND_UPDATED_TICKET_SMS_URL,
  GET_TICKET_BY_PNR_URL
} from './../utils/api-url';
import { RESET_PASSENGER_FORM } from './action-types';


export const cancelTicket=(ticket,travellers,partialCancelStatus,sendCancellationNotification,cancellationPenality,isTravelBoarded=true,onSuccess=()=>{})=>{
  ticket['agent_id'] = global.agentId;
  return fetch(CANCEL_TICKET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"ticket" : {
                  id:ticket.id,
                  trip_id:ticket.trip_id,
                  trip_route_id:ticket.trip_route_id,
                  agent_id:ticket.agent_id        
                },
                "travellers" : travellers,
                "userName" : global.userName,
                "partialCancel" : partialCancelStatus,
                "operatorCode" : global.operatorCode,
                "sendCancellationNotification" : sendCancellationNotification,
                "cancellationPenalityPercentage":cancellationPenality ,
                "agentId":global.agentId,
                "isTravelBoarded":isTravelBoarded
            })
         })
        .then(response => response.json())
        .then(response => {
            if(response === true){
            message.success("Ticket cancelled successfully!");
            onSuccess()
            return true;
        }
        else{
          message.error(`Ticket cancellation failed because of ${response}`);
            return false;
        }
        })
        .catch((reason)=> {
            message.error(`Ticket cancellation failed because of ${reason}`)
            return false;
        }); 
}



export const getTicketById=(ticketId,onSuccess=()=>{},onFailure=()=>{})=>{
    fetch(GET_TICKET_BY_ID_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"ticketId" : ticketId})
        })
        .then(response => response.json())
        .then(response => {
            if(response.Status==="FAILURE"){
                message.error(response.Message);
            }else{
                onSuccess(response);
            }
        })
        .catch((reason)=> {
            message.error(`Ticket fecthing failed because of ${reason}`)   
            onFailure()     
        }); 
}

export const updateTransactionDetails=(transactionDetails,onSuccess=()=>{},onFailure=()=>{})=>{
    console.log({transactionDetails});
    let ticket = {};
    let isSeatTaken = {};
    ticket.id = transactionDetails.ticket.id;
    ticket.phoneNumber = transactionDetails.ticket.phone_number;
    ticket.trip_id = transactionDetails.trip.id;
    ticket.trip_route_id = transactionDetails.ticket.trip_route_id;
    ticket.emailId = transactionDetails.ticket.email_id;
    ticket.updated_by = global.userName;
    ticket.remarks = transactionDetails.ticket.remarks;
    ticket.dropLocationId = transactionDetails.ticket.drop_location;

    let seats = [];
    let travellers = transactionDetails.traveller.map((traveller)=>{
      if (isSeatTaken[traveller.seat_number]){
        message.error("Please select different seats");
        return;
      }
      if (traveller.traveller_status !== 'Confirmed') {
        return;
      }

      let seat = {};
      seat.ticket_id = transactionDetails.ticket.id;
      seat.seatNumber = traveller.seat_number;
      seat.tripId = transactionDetails.trip.id;
      seats.push(seat);
      let temptraveller = {};
      temptraveller.id = traveller.id;
      temptraveller.fullName = traveller.full_name;
      temptraveller.pickup_location = traveller.pickup_location.id;
      temptraveller.gender = traveller.gender;
      temptraveller.seatNumber = traveller.seat_number;
      isSeatTaken[traveller.seat_number] = true;
      return temptraveller;
    });
    var filteredTravellers = travellers.filter(function (el) {
      return el != null;
    });
    fetch(UPDATE_TICKET_URL, {
      method: "POST",
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({"ticket" : ticket,
      "travellers" : filteredTravellers,
      "seats" : seats,
      "websiteUpdate" : false})
      })
      .then((response) => {return response.json()})
      .then((data) => {
        if(data.Status === "SUCCESS"){
        message.success('Ticket details updated successfully');
        onSuccess()
      }
      else{
        message.error(`Ticket Updation failed because of ${data.Message}`);
        onFailure()
      }
      })
      .catch((error)=>{
        message.error(`Ticket updation failed. Please try again ${error}`);
      })
  }

  export const overRidePenality=(travellerIds,penalty,onSuccess=()=>{},onFailure=()=>{})=>{
    return fetch(OVER_RIDE_PENALITY_URL, {
      method: "POST",
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({ travellerIds,penalty})
      })
      .then(response => response.json())
      .then(response => {
          if(response.Status==="FAILURE"){
              message.error(response.Message);
          }else{
              onSuccess(response);
              return true;
          }
      })
      .catch((reason)=> {
          message.error(`Ticket fecthing failed because of ${reason}`)   
          onFailure()     
      }); 
  }

  export const resetPassengerForm=()=>{
    return {
      type: RESET_PASSENGER_FORM
    }
  }

  export const sendTicketUpdateMailById=(ticketId,onSuccess=()=>{},onFailure=()=>{})=>{
    fetch(SEND_UPDATED_TICKET_MAIL_URL, {
      method: "POST",
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({"ticketId" : ticketId})
    })
    .then(response => response.json())
    .then(response => {
        if(response.Status===false){
            onFailure(response.message);
        }else{
            onSuccess(response.message);
        }
    })
    .catch((reason)=> {
        onFailure("Failed to send mail")     
    }); 
  }

  export const sendTicketUpdateSMSById=(ticketId,onSuccess=()=>{},onFailure=()=>{})=>{
    fetch(SEND_UPDATED_TICKET_SMS_URL, {
      method: "POST",
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({"ticketId" : ticketId})
    })
    .then(response => response.json())
    .then(response => {
        if(response.Status===false){
            onFailure(response.message);
        }else{
          onSuccess(response.message);
        }
    })
    .catch((reason)=> {
        onFailure("Failed to send SMS")     
    }); 
  }

export const fetchTicketDetailsByPNR=(pnr,onSuccess, onFailure=()=>{})=>{
  fetch(GET_TICKET_BY_PNR_URL, {
    method: "POST",
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({"pnr" : pnr ,"operatorCode":global.operatorCode,"agentId":global.agentId,getLogs:true,getStatusOfSeat:true})
  })
  .then(response => response.json())
  .then(transactionDetails => {
    if (transactionDetails.Message === "Unable to get ticket details"||transactionDetails.Message === "PNR not found"||transactionDetails.Status==="FAILURE"){
      message.error("Please enter a valid PNR");
      onFailure(transactionDetails.Message)
    }
    else{
      onSuccess(transactionDetails);
    }
  })
}