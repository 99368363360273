import {
    ACTION_VIEW_SEARCH_PAGE_ALLOWED,
    ACTION_VIEW_REPORTS_PAGE_ALLOWED,
    ACTION_VIEW_CANCEL_PAGE_ALLOWED,
    ACTION_VIEW_NOTIFICATION_PAGE_ALLOWED,
    ACTION_VIEW_BUS_CANCELLATION_PAGE_ALLOWED,
    ACTION_VIEW_STAFF_PAGE_ALLOWED,
    ACTION_VIEW_BOOKED_TICKETS_REPORT_ALLOWED,
    ACTION_VIEW_SETTLEMENT_REPORT_ALLOWED,
    ACTION_VIEW_MASTER_TRIP_REPORT_ALLOWED,
    ACTION_VIEW_PASSENGER_INFO_REPORT_ALLOWED,
    ACTION_VIEW_SALES_REPORT_ALLOWED,
    ACTION_VIEW_TICKET_SEARCH_REPORT_ALLOWED,
    ACTION_VIEW_TICKET_SEARCH_REPORT_OLD_ALLOWED,
    ACTION_VIEW_TRIP_REPORT_ALLOWED,
    ACTION_VIEW_BLOCKED_TICKETS_REPORT_ALLOWED,
    ACTION_VIEW_PERFORMANCE_REPORT_ALLOWED,
    ACTION_VIEW_TOTAL_TRANSACTION_REPORT_ALLOWED,
    ACTION_VIEW_WEB_TICKET_CANCELLATION_REPORT_ALLOWED,
    ACTION_TO_VIEW_TRIP_SHEET_PERMISSION_PAGE,
    ACTION_VIEW_ABANDONED_REPORT_ALLOWED,
    ACTION_VIEW_WEB_TICKET_SALES_REPORT_ALLOWED,
    TRIP_EXPENSE_PAGE,
    ACTION_SMS_REPORT_ALLOWED,
    ACTION_VIEW_TICKETS_BOOKED_AFTER_START_TIME_ALLOWED,
    ACTION_VIEW_AGENTS_PAGE_ALLOWED,
    ACTION_VIEW_AGENTS_TRANSACTION_PAGE_ALLOWED,
    ADMIN_VIEW_AGENTS_PAGE,
    ADMIN_VIEW_TRIPS_PAGE,
    ADMIN_VIEW_SCHEDULES_PAGE,
    AGENT_VIEW_STANDARD_STOPS_PAGE,
    ACTION_VIEW_ANALYTICS_DEMAND,
    VIEW_AGENT_TRIP_CREATE,
    ACTION_TO_VIEW_SERVICE_COUNT_REPORT,
    ACTION_TO_VIEW_ACTIVE_BOOKINGS_REPORT,
    PRIORITY_ROUTES_PAGE_ALLOWED,
    ACTION_VIEW_RESCHEDULED_REPORT_PAGE,
    ACTION_VIEW_TICKET_SHARING_REPORT_ALLOWED, VIEW_PREPAID_AGENT_ACCOUNT_DATA,
    ACTION_TO_VIEW_OPERATOR_COST_SUMMARY, ACTION_TO_VIEW_OPERATOR_TRANSACT_SUMMARY, ACTION_VIEW_REVENUE_REPORT_ALLOWED, ADMIN_VIEW_CITIES_PAGE,
    ACTION_TO_VIEW_MAVEN_TRANSACTION_REPORT, ACTION_TO_VIEW_OPERATOR_TRACKING_SUMMARY
} from './../../utils/constant';
import {
    checkActionAllowed
} from './../../utils/utilities';

export const viewTicketBookedAfterTripStartTime=()=>{
    if(checkActionAllowed(ACTION_VIEW_TICKETS_BOOKED_AFTER_START_TIME_ALLOWED)){
        return true;
    }else{
        return false;
    }
}


export const viewSmsReport=()=>{
    if(checkActionAllowed(ACTION_SMS_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const checkSearchPage=()=>{
    if(checkActionAllowed(ACTION_VIEW_SEARCH_PAGE_ALLOWED)){
        return true;
    }else{
        return false;
    }
}


export const checkCancelPage=()=>{
    if(checkActionAllowed(ACTION_VIEW_CANCEL_PAGE_ALLOWED)){
        return true;
    }else{
        return false;
    }
}


export const checkViewNotificationPage=()=>{
    if(checkActionAllowed(ACTION_VIEW_NOTIFICATION_PAGE_ALLOWED)){
        return true;
    }else{
        return false;
    }
}


export const checkBusCancellationPage=()=>{
    if(checkActionAllowed(ACTION_VIEW_BUS_CANCELLATION_PAGE_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const checkStaffPage=()=>{
    if(checkActionAllowed(ACTION_VIEW_STAFF_PAGE_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const checkAgentsPage=()=>{
    if(checkActionAllowed(ACTION_VIEW_AGENTS_PAGE_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const checkAgentsTransactionPage=()=>{
    if(checkActionAllowed(ACTION_VIEW_AGENTS_TRANSACTION_PAGE_ALLOWED)){
        return true;
    }else{
        return false;
    }
}


export const viewBookedTicketsReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_BOOKED_TICKETS_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const viewTicketSharingReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_TICKET_SHARING_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const viewSettlementReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_SETTLEMENT_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}


export const viewMasterTripReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_MASTER_TRIP_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}


export const viewPassengerInfoReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_PASSENGER_INFO_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}


export const viewSalesReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_SALES_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}


export const viewTicketSearchReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_TICKET_SEARCH_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const viewTicketSearchReportOld=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_TICKET_SEARCH_REPORT_OLD_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const viewRescheduledTicketReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_RESCHEDULED_REPORT_PAGE)){
        return true;
    }else{
        return false;
    }
}


export const viewTotalTransactionReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_TOTAL_TRANSACTION_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const viewTripReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_TRIP_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const blockedSeatsReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_BLOCKED_TICKETS_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const cancelledSeatsReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_WEB_TICKET_CANCELLATION_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const viewPerformanceReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_PERFORMANCE_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const viewRevenueReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_REVENUE_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const viewPrePaidReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_TO_VIEW_MAVEN_TRANSACTION_REPORT)){
        return true;
    }else{
        return false;
    }
}

export const checkViewAbandonedReport=()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_ABANDONED_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const checkWebTicketSalesReport = ()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_WEB_TICKET_SALES_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const viewActiveBookings = ()=> {
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_TO_VIEW_ACTIVE_BOOKINGS_REPORT)){
        return true;
    }else{
        return false;
    }
}

export const checkWebTicketCancelReport = ()=>{
    if(checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_WEB_TICKET_CANCELLATION_REPORT_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const checkToViewTripSheetPermissionPage = ()=>{
    if(checkActionAllowed(ACTION_TO_VIEW_TRIP_SHEET_PERMISSION_PAGE)){
        return true;
    }else{
        return false;
    }
}

export const viewDemandDataReport = () => {
    if (checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_VIEW_ANALYTICS_DEMAND)){
        return true;
    }else {
        return false;
    }
}

export const viewServiceCountReport = () => {
    if (checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_TO_VIEW_SERVICE_COUNT_REPORT)){
        return true;
    }else {
        return false;
    }
}

export const viewCostSummaryReportReport = () => {
    if (checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_TO_VIEW_OPERATOR_COST_SUMMARY)){
        return true;
    }else {
        return false;
    }
}

export const viewTransactionSummaryReportReport = () => {
    if (checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_TO_VIEW_OPERATOR_TRANSACT_SUMMARY)){
        return true;
    }else {
        return false;
    }
}

export const viewTrackingSummaryReport = () => {
    if (checkActionAllowed(ACTION_VIEW_REPORTS_PAGE_ALLOWED)&&checkActionAllowed(ACTION_TO_VIEW_OPERATOR_TRACKING_SUMMARY)){
        return true;
    }else {
        return false;
    }
}


export const tripExpenseReport =()=>{
    if(checkActionAllowed(TRIP_EXPENSE_PAGE)){
        return true;
    }else{
        return false;
    }
}

export const tripCreate = () => {
    if (checkActionAllowed(VIEW_AGENT_TRIP_CREATE)){
        return true;
    }
    else {
        return false;
    }
}


export const checkToViewTripsPageOfAdmin = ()=>{
    if(checkActionAllowed(ADMIN_VIEW_TRIPS_PAGE)){
        return true;
    }else{
        return false;
    }
}

export const checkToViewPopularRoutesPageAdmin = ()=>{
    if(checkActionAllowed(PRIORITY_ROUTES_PAGE_ALLOWED)){
        return true;
    }else{
        return false;
    }
}

export const checkToViewCitiesPageAdmin = ()=>{
    if(checkActionAllowed(ADMIN_VIEW_CITIES_PAGE)){
        return true;
    }else{
        return false;
    }
}

export const checkToViewAddressBookPageAdmin = ()=>{
    if(checkActionAllowed(ADMIN_VIEW_CITIES_PAGE)){
        return true;
    }else{
        return false;
    }
}

export const checkToViewSchedulesPageOfAdmin = ()=>{
    if(checkActionAllowed(ADMIN_VIEW_SCHEDULES_PAGE)){
        return true;
    }else{
        return false;
    }
}


export const checkToViewStandardStopsPage = ()=>{
    if(checkActionAllowed(AGENT_VIEW_STANDARD_STOPS_PAGE)){
        return true;
    }else{
        return false;
    }
}


export const checkToViewAgentsPageOfAdmin = ()=>{
    if(checkActionAllowed(ADMIN_VIEW_AGENTS_PAGE)){
        return true;
    }else{
        return false;
    }
}

export const checkPrepaidOTAPage = ()=>{
    if(checkActionAllowed(VIEW_PREPAID_AGENT_ACCOUNT_DATA)){
        return true;
    }else{
        return false;
    }
}
