import { 
    GET_ORIGIN_CITIES,
    GET_DESTINATION_CITIES,FETCH_SCHEDULES , 
    FETCH_SEAT_LAYOUT , 
    BOOK_TICKET, BLOCK_TICKET , 
    SET_SELECTED_SCHEDULE_SEATS ,
    SET_SELECTED_SCHEDULE,
    SET_BOOKED_TICKET,
    ON_PASSENGER_FORM_CHANGE,
    CHANGE_TICKET,
    SET_PICK_UP_POINTS_DETAILS_SELECTED_SCHEDULE,
    SET_DROP_UP_POINTS_DETAILS_SELECTED_SCHEDULE
} from './action-types';
import { 
    GET_SCHEDULE_STOPS_BY_TRIP_ID_URL,
    CHANGE_TICKET_URL , 
    GET_ORIGIN_CITIES_URL, 
    GET_DESTINATION_CITIES_URL,
    FETCH_SCHEDULES_URL,
    FETCH_SEAT_LAYOUT_URL , 
    BOOK_TICKET_URL, 
    BLOCK_TICKET_URL, 
    GET_ROUTES_BY_SCHEDULE_ID_URL,
    RESET_TO_DEFAULT_TIMINGS_URL,
    UPDATE_STOP_TIMING_OF_TRIP_URL, FETCH_LOGS_TRIP_URL
} from '../utils/api-url';
import { postCall , getCall} from "../utils/network.util";
import { getArrayfromString, isValid } from '../utils/utilities';
import { message } from 'antd';

  
export const getOriginCities = (onSuccess=()=>{

}) => {
    return {
        type: GET_ORIGIN_CITIES,
        payload: {
            url:GET_ORIGIN_CITIES_URL,
            api:getCall,
            apiPayload:{
              operatorCode:global.operatorCode
            },
            onSuccess:onSuccess
        }
    }
  }
   
  
export const getDestinationCities = (origin,onSuccess=()=>{

}) => {
    return {
        type: GET_DESTINATION_CITIES,
        payload: {
            url:GET_DESTINATION_CITIES_URL+"?origin="+origin,
            api:getCall,
            onSuccess:onSuccess
        }
    }
  }
   
export const fetchSchedules=(origin,destination,date,isGetSeatAvailibity,showError=true,onSuccess=()=>{

})=>{
    let url = FETCH_SCHEDULES_URL+"?";
    if(isValid(origin)){
        url = url +"origin="+origin+'&';
    }
    if(isValid(destination)){
        url = url +"destination="+destination+'&';
    }
    url = url +"isGetSeatAvailibity="+isGetSeatAvailibity+'&';
    return {
        type:FETCH_SCHEDULES,
        payload:{
            url:url+"date="+date,
            api:getCall,
            onSuccess:onSuccess,
            showError:showError
        }
    }
}

export const fetchSeatLayout = (tripRouteId,onSuccess=()=>{},onFailure=()=>{})  => {
    return{
        type:FETCH_SEAT_LAYOUT,
        payload:{
            url:FETCH_SEAT_LAYOUT_URL,
            api:postCall,
            onSuccess:onSuccess,
            onFailure:onFailure,
            apiPayload:{
                "tripRouteId":tripRouteId,
                "isPassengerInfoNeeded":global.privilege==='Redbus'?false:true,
                "agentId":global.agentId,
                "operatorCode":global.operatorCode

            }
        }
    }  
  };

  export const bookTicket=(ticketPayload,onSuccess=()=>{

  }, onBookFailure=()=>{})=>{
    return{
        type:BOOK_TICKET,
        payload:{
            url:BOOK_TICKET_URL,
            api:postCall,
            onSuccess:onSuccess,
            onBookFailure: onBookFailure,
            apiPayload:ticketPayload
        }
    }  
  }

  export const changeTicket =(oldTicket,newTicket,waiveCharge,isNotify,onSuccess=()=>{})=>{
      return{
          type:CHANGE_TICKET,
          payload:{
              url:CHANGE_TICKET_URL,
              api:postCall,
              onSuccess:onSuccess,
              apiPayload:{
                  oldTicket,newTicket,
                  isWaiveCharge:waiveCharge,
                  isNotify:isNotify,
                  isNoPenality:true
              }
          }
      }
  }

  
export const blockTicket=(ticketPayload,onSuccess=()=>{

})=>{
  return{
      type:BLOCK_TICKET,
      payload:{
          url:BLOCK_TICKET_URL,
          api:postCall,
          onSuccess:onSuccess,
          apiPayload:ticketPayload
      }
  }  
}

export const setSelectedScheduleSeats=(seats)=>{
    return{
        type:SET_SELECTED_SCHEDULE_SEATS,
        payload:seats
    }
}

export const setSelectedSchedule=(schedule)=>{
    return{
        type:SET_SELECTED_SCHEDULE,
        payload:schedule
    }
}

export const setPickupPointDetailsForSelectedSchedule=(payload)=>{
    return{
        type:SET_PICK_UP_POINTS_DETAILS_SELECTED_SCHEDULE,
        payload:payload
    }
}

export const setDropPointDetailsForSelectedSchedule=(payload)=>{
    return{
        type:SET_DROP_UP_POINTS_DETAILS_SELECTED_SCHEDULE,
        payload:payload
    }
}

export const setBookedTicketObject=(obj)=>{
    return{
        type:SET_BOOKED_TICKET,
        payload:obj
    }
}

export const onPassengerFormChange=({age,gender,fullName,seatId})=>{
    return {
        type:ON_PASSENGER_FORM_CHANGE,
        payload:{
            age,gender,fullName,seatId
        }
    }
}

export const getViaCities=(scheduleId,onSuccess,onFailure)=>{
      postCall(GET_ROUTES_BY_SCHEDULE_ID_URL,{"scheduleId" :scheduleId})
      .then((findresponce) =>
        {
          if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
             onSuccess(findresponce.payload.Payload.routes);
           }else{
            onFailure();
             if(findresponce.status === "SUCCESS"){
              message.error(findresponce.payload.Message);
             }else{
              message.error("Failed to get cities!");
             }
           }
        })
  }

export const getScheduleStopsByTripIdWithCities=(scheduleId,tripId,onSuccess=()=>{},onFailure=()=>{})=>{
    postCall(GET_SCHEDULE_STOPS_BY_TRIP_ID_URL,{
        scheduleId,tripId
    })
    .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
        onSuccess(findresponce.payload.payload);
       }else{
        onFailure();
         if(findresponce.status === "SUCCESS"){
          message.error(findresponce.payload.Message);
         }else{
          message.error("Failed to get stops!");
         }
       }
    })
}


export const resetToDefaultTimings=(scheduleId,tripId,onSuccess=()=>{},onFailure=()=>{})=>{
    postCall(RESET_TO_DEFAULT_TIMINGS_URL,{
        scheduleId,tripId
    })
    .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
        onSuccess(findresponce.payload.payload);
       }else{
        onFailure();
         if(findresponce.status === "SUCCESS"){
          message.error(findresponce.payload.Message);
         }else{
          message.error("Failed to get stops!");
         }
       }
    })
}

export const updateStopTimingsOfTrip=(scheduleId,tripId,pickupPoints,dropPoints,cities,onSuccess=()=>{},onFailure=()=>{})=>{
    let phoneNumbers = getArrayfromString(global.travelsPhoneNumber);
    postCall(UPDATE_STOP_TIMING_OF_TRIP_URL,{
        scheduleId,tripId,pickupPoints,dropPoints,cities,phoneNumbers
    })
    .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
        onSuccess(findresponce.payload.payload);
       }else{
         if(findresponce.status === "SUCCESS"){
            onFailure(findresponce.payload.Message);
         }else{
            onFailure("Failed to update timings");
         }
       }
    })
}

export const fetchLogsByTrip= (scheduleId,tripId,onSuccess=()=>{},onFailure=()=>{}) => {
    postCall(FETCH_LOGS_TRIP_URL,{scheduleId,tripId})
    .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
        onSuccess(findresponce.payload.Payload);
       }else{
         if(findresponce.status === "SUCCESS"){
            onFailure(findresponce.payload.Message);
        }else{
            onFailure("Failed to get trip logs");
         }
       }
    })
}