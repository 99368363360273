import{
    FETCH_REVENUE_REPORT
} from './action-types';
import{
    FETCH_REVENUE_REPORT_URL,
} from '../utils/api-url';
import { postCall } from "../utils/network.util";
import { getAPIDateFormat } from '../utils/date.utils';

export const getRevenueData = (startDate,endDate,onSuccess=()=>{},onFailure=()=>{}) => {
    return {
        type: FETCH_REVENUE_REPORT,
        payload: {
            url:FETCH_REVENUE_REPORT_URL,
            api:postCall,
            apiPayload:{
              operatorCode:global.operatorCode,
              "travelsId":global.travelsId,
              "startDate":startDate,
              "endDate":endDate
            },
            onSuccess:onSuccess,
            onFailure:onFailure
        }
    }
  }