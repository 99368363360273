import React, { useState , useContext} from "react";
import '../../styles/schedule.style.scss';
import { Row,Col, DatePicker , Form, Select, Button , Radio , List , Typography } from 'antd';
import { addMonth, currentDate, subtractMonth } from "../../utils/date.utils";
import { getActiveSchedules, getInActiveSchedules, fetchAmenitiesAction } from "../../actions/admin.action";
import { useEffect } from "react";
import Icon from '@ant-design/icons';
import Clock from './../../images/filter.png';
import { getMonth ,getYear } from './../../utils/date.utils';
import { DeviceContext } from "../../components/device-provider/device-provider";
import { ScheduleListCalender } from "../../components/schedule-components/render-calender/render-calender.component";
import { isValidArray, sortArrayObject } from "../../utils/utilities";
import { RenderTabs } from "../../components/schedule-components/render-tabs/render-tabs.component";
import { loadingAction } from "../../actions/loading-actions";
import { useDispatch } from "react-redux";
import { ViaCitiesComponent } from "../../components/via-cities-component/via-cities.component";
import {
    LeftOutlined,
    RightOutlined
   } from '@ant-design/icons';
import ScheduleTripPdf from "../../components/schedule-components/schedule-trip-pdf/schedule-trip.pdf";

const {
    Text
} = Typography ;

const {
    Option
} = Select;

const getFilterOptions=(activateSchedules,inActiveSchedules)=>{
    let originFilters = [],destinationFilters = [];

    activateSchedules.forEach((trip)=>{
        if(originFilters.indexOf(trip.origin) === -1){
            originFilters.push(trip.origin);
        }
        if(destinationFilters.indexOf(trip.destination) === -1){
            destinationFilters.push(trip.destination);
        }
    });
    inActiveSchedules.forEach((trip)=>{
        if(originFilters.indexOf(trip.origin) === -1){
            originFilters.push(trip.origin);
        }
        if(destinationFilters.indexOf(trip.destination) === -1){
            destinationFilters.push(trip.destination);
        }
    });

    return {
        originFilters:sortArrayObject(originFilters,true),
        destinationFilters:sortArrayObject(destinationFilters,true)
    }
}

const printOriginOptions = (originFilters) => {
    let options = [] ;
    options.push(<Option key = {'all'} value= {'all'} data={'all'}>All</Option>);
    if(isValidArray(originFilters)){
        originFilters.map((city) => {
            options.push(<Option key = {city} value= {city} data={city}>{city}</Option>);
        });
    }
    return options;
}

const printDestinationOptions = (destinationFilters) => {
    let options = [] ;
    options.push(<Option key = {'all'} value= {'all'} data={'all'}>All</Option>);
    if(isValidArray(destinationFilters)){
        destinationFilters.map((city) => {
            options.push(<Option key = {city} value= {city} data={city}>{city}</Option>);
        });
    }
    return options;
}

const filterData=(activeSchedules,inActiveSchedules,origin,destination,scheduleType)=>{
    let totalSchedules =[];
    if(scheduleType !== 'inactive'){
        activeSchedules.forEach((schedule)=>{
            let isOriginValid = origin === 'all' || schedule.origin === origin ? true : false;
            let isDestionationValid = destination === 'all' || schedule.destination === destination ? true : false;
            let isScheduleActiveTypeValid = scheduleType === 'all' || (scheduleType === "active" && schedule.trips_created === 1 && isValidArray(schedule.trips) && schedule.trips.length > 0) ? true : false;
            let isSchedulePreActiveTypeValid = scheduleType === 'all' || (scheduleType === "previouslyactive" && schedule.trips_created === 1 && schedule.is_future_trip === 0) ? true : false;
            if(isOriginValid && isDestionationValid &&(isScheduleActiveTypeValid || isSchedulePreActiveTypeValid)){
                totalSchedules.push(schedule);
            }
        })
    }

    if(scheduleType === 'all' || scheduleType === 'inactive'){
        inActiveSchedules.forEach((schedule)=>{
            let isOriginValid = origin === 'all' || schedule.origin === origin ? true : false;
            let isDestionationValid = destination === 'all' || schedule.destination === destination ? true : false;
            if(isOriginValid && isDestionationValid){
                totalSchedules.push(schedule);
            }
        })
    }

    return totalSchedules;
}

export const SchedulePage = (props) => {

    const [scheduleDate , setScheduledDate] = useState(currentDate());
    const [showFilter , setShowFilter] = useState(false);
    const [origins , setOrigins] = useState([]);
    const [destinations , setDestinations] = useState([]);
    const [activeSchedules , setActiveSchedules] = useState([]);
    const [inActiveSchedules , setInActiveSchedules] = useState([]);
    const [filteredActiveSchedules , setFilteredActiveSchedules] = useState([]);
    const [filteredInActiveSchedules , setFilteredInActiveSchedules] = useState([]);
    const [dataSource , setDataSource] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [loadData,setLoadData] = useState(false);

    const [filteredOptions , setFilteredOptions] = useState({
        origin:'all',
        destination:'all',
        scheduleType:'active'    
    });

    
    const {
        isMobile
    } = useContext(DeviceContext);

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const onChange = (date, dateString) => {
        setScheduledDate(date);
      };

    const onFinish=(values)=>{
        let{
            origin,
            destination,
            scheduleType
        } = values;

       setFilteredOptions(values);
       let totalSchedules = filterData(activeSchedules,inActiveSchedules,origin,destination,scheduleType);
       setDataSource(totalSchedules);
    }

    const setLoading = (value)=>{
        dispatch(loadingAction(value));
    }

    const onActiveSucess=(data)=>{
        let {
            schedules
        } = data;
        let{
            origin,
            destination,
            scheduleType
        } = filteredOptions;
        setActiveSchedules(schedules);
        let totalActiveSchedules = filterData(schedules,[],origin,destination,scheduleType);
        setFilteredActiveSchedules(totalActiveSchedules);
        setLoading(false);
        setLoadData(true);
    }

    const onActiveFailure=()=>{
        setLoading(false);
        setLoadData(false);
    }

    const onInActiveSucess=(data)=>{
        let {
            schedules
        } = data;
        setInActiveSchedules(schedules);
        setFilteredInActiveSchedules(schedules);
        // Note no need get active setting false 
        // setLoading(false);
    }

    const onInActiveFailure=()=>{
        // Note no need get active setting false
        // setLoading(false);
    }

    useEffect(()=>{
            reload();
    },[scheduleDate]);

    useEffect(()=>{
        inActiveReload();
        fetchAmenities();
    },[]);

    const fetchAmenities = () => {
        fetchAmenitiesAction(setAmenities);
    }

    const reload=()=>{
        setLoading(true);
        let month = getMonth(scheduleDate);
        let year = getYear(scheduleDate)
        getActiveSchedules(month+1,year,onActiveSucess,onActiveFailure);
    }

    const onClickFilter=()=>{
        setShowFilter(!showFilter);
    }

    const inActiveReload =()=>{
        getInActiveSchedules(onInActiveSucess,onInActiveFailure);
    }
      
    useEffect(()=>{
        if(filteredOptions.scheduleType === 'all'){
            setDataSource(filteredActiveSchedules.concat(filteredInActiveSchedules));
        }else{
            setDataSource(filteredActiveSchedules);
        }
    },[filteredActiveSchedules,filteredInActiveSchedules]);

    useEffect(()=>{
        let{
            originFilters,
            destinationFilters
        } = getFilterOptions(activeSchedules,inActiveSchedules);    

        setOrigins(originFilters);
        setDestinations(destinationFilters);

    },[activeSchedules,inActiveSchedules]);

    return (
        <div className="schedule-page-inner-div">
           <Row justify="space-between">
                <Col>
                    <Button ghost className="open-select-seat-btn" onClick={onClickFilter}>
                        <Icon component={() => (<img src={Clock} style={{width:'23px',padding:'1px'}} />)} />
                    </Button>
                </Col>
                <Col xs={{span:14}} sm={{span:14}} md={{span:12}} lg={{span:6}} xl={{span:5}}>
                    <Row>
                        <Col>
                            <Button  className="schedule-left-arrow" onClick={()=>{
                                setScheduledDate(subtractMonth(scheduleDate,1));
                            }}>
                                <LeftOutlined />
                            </Button>
                        </Col>
                        <Col>
                            <DatePicker 
                                defaultValue={scheduleDate}
                                value={scheduleDate}
                                size="large"
                                format="MMM YYYY"
                                onChange={onChange} 
                                picker="month" />
                        </Col>
                        <Col>
                            <Button  className="schedule-right-arrow" onClick={()=>{
                                setScheduledDate(addMonth(scheduleDate,1));
                            }}>
                                <RightOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {showFilter&&
                <Form
                form={form}
                initialValues={{ origin : 'all' , destination : 'all' , scheduleType: 'active'}}
                className = "schedule-filter-form"
                onFinish={onFinish}
                layout="vertical"
                >
                    <Row gutter={[15,15]}>
                        <Col>
                            <Form.Item
                                label="Origin"                           
                                rules={[{ required: false, message: '' }]}
                                name="origin"
                                >
                                <Select
                                    className = "schedule-origin-block"
                                    placeholder="Select origin"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {printOriginOptions(origins)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Destination"
                                rules={[{ required: false, message: '' }]}
                                name="destination"
                                >
                                <Select
                                    className = "schedule-destination-block"
                                    placeholder="Select destination"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {printDestinationOptions(destinations)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                        <Form.Item>
                            <Button className="filter-button" htmlType="submit">Filter</Button>
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                    <Form.Item label="" name="scheduleType">
                        <Radio.Group>
                            <Radio value="all">All</Radio>
                            <Radio value="active">Active</Radio>
                            <Radio value="inactive">Inactive</Radio>
                            <Radio value="previouslyactive">Previously Active</Radio>
                        </Radio.Group>
                    </Form.Item>
                    </Row>
                </Form>
            }
            {loadData?
                <List
                className="schedule-list-root"
                dataSource={dataSource}
                header={null}
                renderItem={item => {
                    let asTripsForMonth = isValidArray(item.trips)?true:false;
                    if(asTripsForMonth||true){
                        return (
                            <div className="schedule-list">
                                <Row gutter={[16,16]} justify = "space-between" align="middle">
                                    <Col>
                                        <Row justify="center" align="middle">
                                            <Col>
                                                <div className="description-block">
                                                    {item.description}
                                                </div>
                                            </Col>
                                            <Col>
                                                <ViaCitiesComponent 
                                                    scheduleId={item.id}
                                                    className="via-block"
                                                />
                                            </Col>
                                            <Col>
                                                <div>
                                                    <ScheduleTripPdf
                                                        scheduleId={item.id}
                                                        description = {item.description}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {!isMobile&&
                                    <Col>
                                        <div>
                                            <ScheduleListCalender 
                                                selectedDate={scheduleDate}
                                                scheduleData={item}
                                            />
                                        </div> 
                                    </Col>
                                    }
                                </Row>
                                <div className="schedule-tabs-block">
                                    <RenderTabs
                                        selectedDate={scheduleDate}
                                        scheduleData={item}
                                        isMobile={isMobile}
                                        amenities = {amenities}
                                        fetchAmenities= {fetchAmenities}
                                        reload={reload}
                                    />
                                </div>
                            </div>
                        )
                    }
                }}
                />
                :null
            }
        </div>
    )
}