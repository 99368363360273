import React  from 'react';
import { Menu , Button} from 'antd';
import { WEB_RELEASE_VERSION } from './../../app.config';
import { 
  checkSearchPage,
  checkCancelPage,
  checkViewNotificationPage,
  checkBusCancellationPage,
  checkStaffPage,
  viewBookedTicketsReport,
  viewMasterTripReport,
  viewPassengerInfoReport,
  viewSalesReport,
  viewTicketSearchReport,
  viewTicketSearchReportOld,
  viewRescheduledTicketReport,
  viewTripReport,
  blockedSeatsReport,
  viewPerformanceReport,
  viewTotalTransactionReport,
  cancelledSeatsReport,
  checkViewAbandonedReport,
  checkWebTicketSalesReport,
  checkWebTicketCancelReport,
  viewSmsReport,
  viewTicketBookedAfterTripStartTime,
  checkAgentsPage,
  checkAgentsTransactionPage,
  viewSettlementReport,
  tripExpenseReport,
  checkToViewTripsPageOfAdmin,
  checkToViewPopularRoutesPageAdmin,
  checkToViewSchedulesPageOfAdmin,
  checkToViewAgentsPageOfAdmin,
  viewDemandDataReport,
  viewServiceCountReport,
  viewCostSummaryReportReport,
  viewTransactionSummaryReportReport,
  tripCreate,
  checkToViewStandardStopsPage,
  checkToViewTripSheetPermissionPage,
  viewTicketSharingReport,
  viewRevenueReport,
  checkToViewCitiesPageAdmin,
  checkPrepaidOTAPage,
  checkToViewAddressBookPageAdmin,
  viewPrePaidReport,
  viewTrackingSummaryReport
 } from './app.header.module';
import { useLocation } from 'react-router-dom'

const { SubMenu } = Menu;


export const MenuMobile = (props) => {

  const {
    onClickAddressBookPage,
    onTicketSearchReportClick,
    onTicketSearchV2ReportClick,
    onRescheduledTicketReportClick,
    onMasterTripSheetReport,
    onViewPassengerInfoReportClick,
    onSalesReportClick,
    onBookedTicketsClick,
    onTripSheetClick,
    logout,
    changePassword,
    viewCancelClick,
    searchClick,
    onClick,
    userName,
    onBlockedReportClick,
    performanceReport,
    totalTransactionReport,
    cancelledSeatsReportClick,
    viewAbandonedReportClick,
    webTicketSalesReportClick,
    webTicketCancelReportClick,
    onClickSmsReport,
    onClickTicketsBookedAfterTripStartTime,
    onClickAgentTransactionsPage, 
    onClickAgentTripSheetPermissionPage,
    onClickAgentsPage,
    tripExpenseClick,
    onClickSettlementReport,
    onClickGstFilingReport,
    onClickDemandDataReport,
    onClickServiceCountReport,
    onClickCostSummaryReport,
    onClickTransactionSummaryReport,
    onClickTrackingSummaryReport,
    onClickNewTrip,
    onClickStandardStops,
    openAdminTripsPage,
    openAdminSchedulesPage,
    openAdminAgentsPage,
    staffClick,
    tripCreatePage,
    openSchedulesPage,
    popularRoutesPage,
    onClickTicketSharing,
    onClickRevenue,
    onClickTransactions,
    onClickCitiesPage,
    onPrepaidOTADashboardClick
  }=props;
  
  const location = useLocation()

  return (
    <Menu onClick={onClick} defaultSelectedKeys={[location.pathname]}>
                {checkSearchPage()&&
                <Menu.Item key="/home" onClick={searchClick}>Search</Menu.Item>
                }
                {viewTicketSearchReport()&&
                <Menu.Item key="/view-ticket-search" onClick={onTicketSearchV2ReportClick}>Ticket Search</Menu.Item>
                }
                {checkCancelPage()&&
                <Menu.Item key="/view-cancel" onClick={viewCancelClick}>Modify/Cancel</Menu.Item>
                }
                {checkPrepaidOTAPage()&&
                <Menu.Item key="/prepaid-ota" onClick={onPrepaidOTADashboardClick}>Dashboard</Menu.Item>
                }
                <SubMenu key="report" title="Report" style={{backgroundColor:'red'}}>
                  {viewTripReport()||viewMasterTripReport()?
                    <SubMenu title="Trip Sheet">
                        {viewTripReport()&&
                          <Menu.Item key="/report/tripsheet" onClick={onTripSheetClick}>Trip Sheet</Menu.Item>
                        }
                        {viewMasterTripReport()&&
                          <Menu.Item key="/report/mastertripsheetreport" onClick={onMasterTripSheetReport}>Master Trip Sheet Report</Menu.Item>
                        }
                    </SubMenu>
                    :null
                  }
                  {viewSalesReport()||viewBookedTicketsReport()?
                    <SubMenu title="Sales">
                      {viewSalesReport()&&
                        <Menu.Item key="/report/salesreport" onClick={onSalesReportClick}>Sales Report</Menu.Item>
                      }
                      {viewBookedTicketsReport()&&
                        <Menu.Item key="/report/bookedtickets" onClick={onBookedTicketsClick}>Booked Tickets</Menu.Item>
                      }
                  </SubMenu>
                  :null
                  }
                  {checkWebTicketSalesReport()||checkWebTicketCancelReport()?
                  <SubMenu title="Web Tickets">
                      {checkWebTicketSalesReport()&&
                        <Menu.Item key="/report/webticketsalesreport" onClick={webTicketSalesReportClick}>Web Ticket Sales Report</Menu.Item>
                      }
                      {checkWebTicketCancelReport()&&
                        <Menu.Item key="/report/webticketcancelreport" onClick={webTicketCancelReportClick}>Web Ticket Cancellation Report</Menu.Item>
                        }
                  </SubMenu>
                  :null
                  }
                  <SubMenu title="Other Reports">
                       {viewTotalTransactionReport()&&
                        <Menu.Item key="/report/totaltransactionreport" onClick={totalTransactionReport}>Total Transaction Summary</Menu.Item>
                        }
                        
                        {cancelledSeatsReport()&&
                        <Menu.Item key="/report/cancelledticketreport" onClick={cancelledSeatsReportClick}>Ticket Cancellation Report</Menu.Item>
                        }
                        {blockedSeatsReport()&&
                        <Menu.Item key="/report/blockedreport" onClick={onBlockedReportClick}>Blocked Tickets</Menu.Item>
                        }
                        {viewPassengerInfoReport()&&
                        <Menu.Item key="/report/viewpassengersreport" onClick={onViewPassengerInfoReportClick}>View Passenger Info</Menu.Item>
                        }
                        {viewTicketSearchReportOld()&&
                        <Menu.Item key="/report/ticketsearchreport" onClick={onTicketSearchReportClick}>Ticket Search</Menu.Item>
                        }
                        {viewRescheduledTicketReport()&&
                        <Menu.Item key="/report/rescheduledTicketreport" onClick={onRescheduledTicketReportClick}>Rescheduled Ticket</Menu.Item>
                        }

                        {viewPerformanceReport()&&
                        <Menu.Item key="/report/performancereport" onClick={performanceReport}>Performance Report</Menu.Item> 
                        }
                       
                        {viewSmsReport()&&
                        <Menu.Item key="/report/smsreport" onClick={onClickSmsReport}>SMS Report</Menu.Item>
                        }
                        {/* <Menu.Item key="report:7">View Current Info</Menu.Item> */}
                        {/* <Menu.Item key="report:10">Web Ticket Cancellation Report</Menu.Item> */}
                        {/* <Menu.Item key="report:11">Performance Report</Menu.Item> */}
                        {checkViewAbandonedReport()&&
                        <Menu.Item key="/report/abandonedreport" onClick={viewAbandonedReportClick}>Abandoned Transactions Report</Menu.Item>
                        }
                        {viewTicketBookedAfterTripStartTime()&&
                        <Menu.Item key="/report/ticketbookedafterdeparture" onClick={onClickTicketsBookedAfterTripStartTime}>Tickets Booked After Departure</Menu.Item>
                        }
                        {viewSettlementReport()&&
                        <Menu.Item key="/report/settlementreport" onClick={onClickSettlementReport}>Settlement Report</Menu.Item>
                        }
                        {viewSettlementReport()&&
                        <Menu.Item key="/report/gstfilingreport" onClick={onClickGstFilingReport}>GST Filing Report</Menu.Item>
                        }
                        {viewDemandDataReport()&&
                        <Menu.Item key = "/report/demandDataReport" onClick = {onClickDemandDataReport}>Demand Data Report</Menu.Item>
                        }
                        {viewServiceCountReport()&&
                        <Menu.Item key = "/report/viewServiceCountReport" onClick = {onClickServiceCountReport}>Service Count</Menu.Item>
                        }
                        {viewCostSummaryReportReport()&&
                        <Menu.Item key = "/report/costSummaryReport" onClick = {onClickCostSummaryReport}>Billing Report</Menu.Item>
                        }
                        {viewTransactionSummaryReportReport()&&
                        <Menu.Item key = "/report/transactionSummaryReport" onClick = {onClickTransactionSummaryReport}>Transaction Summary</Menu.Item>
                        }
                        {viewTrackingSummaryReport()&&
                        <Menu.Item key = "/report/trackingSummaryReport" onClick = {onClickTrackingSummaryReport}>Tracking Summary</Menu.Item>
                        }
                        {viewTicketSharingReport()&&
                        <Menu.Item key = "/report/ticketsharingreport" onClick = {onClickTicketSharing}>Seat Sharing Report</Menu.Item>
                        }
                        {viewRevenueReport()&&
                        <Menu.Item key = "/report/revenuereport" onClick = {onClickRevenue}>Expected Revenue Report</Menu.Item>
                        }
                        {viewPrePaidReport()&&
                        <Menu.Item key = "/report/prepaidtransactions" onClick = {onClickTransactions}>Maven Payments</Menu.Item>
                        }

                  </SubMenu>
                </SubMenu>
                {tripExpenseReport()&&
                <Menu.Item key="/trip-expense" onClick={tripExpenseClick}>Trip Expense</Menu.Item>    
                }
                {(checkAgentsPage()||checkAgentsTransactionPage()||checkToViewTripSheetPermissionPage())?
                  <SubMenu key="accounts" title="Accounts">
                    {checkAgentsPage()&&
                      <Menu.Item key="/accounts/agents" onClick={onClickAgentsPage}>Agents</Menu.Item>
                    }
                    {checkAgentsTransactionPage()&&
                      <Menu.Item key="/accounts/agent-transactions" onClick={onClickAgentTransactionsPage}>Agent Transactions</Menu.Item>
                    }
                    {checkToViewTripSheetPermissionPage()&&
                      <Menu.Item key="accounts/trip-sheet-permission" onClick={onClickAgentTripSheetPermissionPage}>Trip sheet permission</Menu.Item>
                    }                    
                  </SubMenu>
                :
                null
                }
                {checkToViewCitiesPageAdmin()||checkToViewAddressBookPageAdmin()||checkToViewAgentsPageOfAdmin()||checkToViewTripsPageOfAdmin()||checkToViewSchedulesPageOfAdmin()||checkToViewPopularRoutesPageAdmin()||checkToViewStandardStopsPage()?
                   <SubMenu key="admin" title="Admin">
                      {checkToViewCitiesPageAdmin()&&
                        <Menu.Item key="/admin/cities" onClick={onClickCitiesPage}>Cities</Menu.Item>
                      }
                      {checkToViewAddressBookPageAdmin()&&
                        <Menu.Item key="/admin/addressbook" onClick={onClickAddressBookPage}>Address Book</Menu.Item>
                      }
                      {checkToViewTripsPageOfAdmin()&&
                        <Menu.Item key="/trips" onClick={openAdminTripsPage}>Trips</Menu.Item>
                      }
                      {checkToViewTripsPageOfAdmin()&&
                        <Menu.Item key="/new-trip" onClick={onClickNewTrip}>In active trips</Menu.Item>
                      }
                      {checkToViewStandardStopsPage()&&
                      <Menu.Item key="/standard-stops" onClick={onClickStandardStops}>Standard stops</Menu.Item>
                      }
                      {checkToViewSchedulesPageOfAdmin()&&
                      <Menu.Item key="/schedules" onClick={openSchedulesPage}>Schedules</Menu.Item>
                      }
                      {checkToViewSchedulesPageOfAdmin()&&
                        <Menu.Item key= "/admin-schedules" onClick= {openAdminSchedulesPage}>Admin Schedules</Menu.Item>
                      }
                      {checkToViewAgentsPageOfAdmin()&&
                        <Menu.Item key="/agents" onClick={openAdminAgentsPage}>Agents</Menu.Item>
                      }
                      {checkStaffPage()&&
                        <Menu.Item key="/staff" onClick={staffClick}>Staff</Menu.Item>
                      }
                      {tripCreate()&&
                        <Menu.Item key= "/trip-create" onClick= {tripCreatePage}>Create Trip</Menu.Item>
                      }
                      {checkToViewPopularRoutesPageAdmin()&&
                        <Menu.Item key= "/popular-routes" onClick= {popularRoutesPage}>Popular Routes</Menu.Item>
                      }
                    </SubMenu>
                  :
                  null
                }
                {checkToViewSchedulesPageOfAdmin()&&
                  <Menu.Item key="/schedules" onClick={openSchedulesPage}>
                    Schedules
                  </Menu.Item>
                 }
                <SubMenu key="profile" title="Profile">
                  <Menu.Item key="/change-password" onClick={changePassword}>Change Password</Menu.Item>
                  <Menu.Item key="/log-out" onClick={logout}>Logout</Menu.Item>
                </SubMenu>
            <Button type="text" className="outline-button">{userName+" "+WEB_RELEASE_VERSION}</Button>
    </Menu>
  );
};