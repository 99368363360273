import { takeLatest, all ,fork , call , put} from 'redux-saga/effects';
import {
    FETCH_REVENUE_REPORT, SET_REVENUE_REPORT
} from '../actions/action-types';

export function* revenueSaga(action) {
    try {

     const {
        api,
        url,
        onSuccess,
        onFailure,
        apiPayload
    } = {...action.payload};

        const apiResult = yield call(api,url,apiPayload);

        if(apiResult.status==="SUCCESS"){
            let clientPayload=apiResult.payload;
           if(clientPayload.Status==='SUCCESS'){
             let serverPayload=clientPayload.Payload;
              yield put({
                  type: SET_REVENUE_REPORT,
                  payload: {revenueDetails:serverPayload.revenueDetails}
              });
              onSuccess();
            }else{
                onFailure();
            }
          }else{
            onFailure();
          }
    }
    catch (error) {
        console.log("getting revenue details failed :",{error});
    }
};


function* watchRevenueSaga() {
    yield takeLatest(FETCH_REVENUE_REPORT, revenueSaga)
}
  
export default function* watchRevenueSagas() {
    yield all([
      fork(watchRevenueSaga)      
    ])
}
  