/**
 * @author Rejo Varghese
 * @description Seat click ticket module
 */

import { GET_DROP_LOCATION_BY_TRIP_ID_URL, GET_PICKUP_LOCATION_BY_TRIP_ID_URL } from "./../../utils/api-url";


export const getPickupLocationByTripId=(scheduleId, date, tripId, onSuccess=()=>{},onFailure=()=>{})=>{

fetch(GET_PICKUP_LOCATION_BY_TRIP_ID_URL, {
    method: "POST",
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({
        "scheduleId": scheduleId,
        "date": date,
        "tripId": tripId,
        "operatorCode" : global.operatorCode,
        "travelsId" : global.travelsId  
    })
    })
    .then(response => response.json())
    .then(data =>{
        onSuccess(data.Payload);
    }).catch((err)=>{
        console.log({err});
        onFailure("Failed to get ticket info!");
    })
}

export const getDropLocationByTripId=(scheduleId, date, tripId, onSuccess=()=>{},onFailure=()=>{})=>{

    fetch(GET_DROP_LOCATION_BY_TRIP_ID_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            "scheduleId": scheduleId,
            "date": date,
            "tripId": tripId,
            "operatorCode" : global.operatorCode,
            "travelsId" : global.travelsId  
        })
        })
        .then(response => response.json())
        .then(data =>{
            onSuccess(data.Payload);
        }).catch((err)=>{
            console.log({err});
            onFailure("Failed to get ticket info!");
        })
    }