import { Form , Row , Col , DatePicker , Button , Table} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getRangeOptions } from './reports.module';
import { getTransactionCountAction } from '../../actions/reports.action';
import { getAPIDateFormat } from "../../utils/date.utils";
import { loadingAction } from "../../actions/loading-actions";

const { RangePicker } = DatePicker;
let allowedMaxDaysRange=31;

export const TransactionSummaryReport = (props) => {

    const [form] = Form.useForm();
    const dispatch=useDispatch();
    const [transactionSummary,setTransactionSummary] = useState([]);


    const onSuccess=(data)=>{
        setTransactionSummary(data);
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const onFinish=(values)=>{
        let {
            dateRange
        }=values;
        setLoading(true);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);  
        getTransactionCountAction(fromDate,toDate,onSuccess,onFailure);
    }

    
    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const columns = [
        {
            title:"Ticket Count",
            dataIndex:"ticket_count",
            key:"ticket_count"
        },
        {
            title: "Amount Paid",
            dataIndex: "amount_paid",
            key: "amount_paid"
        },
        {
            title:"Traveller Count",
            dataIndex:"traveller_count",
            key:"traveller_count"
        }

    ];

  
      return (
          <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                            rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Get</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col>
                            <Table 
                                columns={columns}
                                dataSource={transactionSummary}
                                pagination={false}
                            />
                    </Col>
                </Row>
          </div>
      )
  }
            

      
